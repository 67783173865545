import { useEffect } from 'react'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { useHistory } from 'react-router-dom'
import logo from '../../assets/LogoHseqtechcl.png'
import SigninForm from './SigninForm'
import AuthLayout from '../layouts/AuthLayout'
import useAuth from '../../hooks/useAuth'

const Brand = () => (
  <img
    style={{
      width: '300px',
      height: '105px',
      display: 'block',
      margin: '0 auto 0 auto',
    }}
    src={logo}
    alt="Pepsico"
  />
)
const Signin = () => {
  const { user } = useAuth()

  const history = useHistory()
  useEffect(() => {
    if (user) {
      console.log(user)
      history.push('/start')
    }
  })

  return (
    <>
      {!user
        && (
          <AuthLayout>
            <Brand />
            <Paper
              sx={{ padding: (theme) => theme.spacing(6) }}
            >
              <Typography
                color="primary"
                component="h1"
                variant="h4"
                align="center"
              >
                Bienvenido/a!
              </Typography>
              <Typography
                component="h2"
                variant="body1"
                align="center"
              >
                Por favor inicie sesión para continuar
              </Typography>
              <SigninForm />
            </Paper>
          </AuthLayout>
        )}
    </>
  )
}

export default Signin