import TextField from '@mui/material/TextField'
import { useFormik } from 'formik'
import { Link } from 'react-router-dom'
import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth'
import { toast } from 'react-toastify'
import { auth, FirebaseError } from '../../config/firebaseConfig'
import validationSchema from './validationSchema'
import ColorButton from '../../common/ColorButton'

const SigninForm = function SigninForm() {
  const [
    signInWithEmailAndPassword,
  ] = useSignInWithEmailAndPassword(auth)

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: (values) => {
      console.log(
        'Ha iniciado sesión',
        JSON.stringify(values, null, 2),
      )
    },
  })

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          sx={{ my: 2 }}
          fullWidth
          id="email"
          name="email"
          label="Email"
          value={formik.values.email.trim()}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          sx={{ my: 2 }}
          fullWidth
          id="password"
          name="password"
          label="Contraseña"
          type="password"
          value={formik.values.password.trim()}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
        <ColorButton
          onClick={async () => {
            console.log(formik.values.email && 'Email : ', formik.values.email)
            if (!formik.values.email) {
              toast.error('Por favor ingrese un email')
              return
            }
            if (!formik.values.password) {
              toast.error('Por favor ingrese una contraseña')
              return
            }
            try {
              await signInWithEmailAndPassword(formik.values.email, formik.values.password)
              toast.success(`Ha iniciado sesión como ${formik.values.email}`)
            } catch (error) {
              let message: string
              if (error instanceof Error) {
                message = error.message
              } else if (error instanceof FirebaseError) {
                message = error.code
              } else {
                message = JSON.stringify(error)
              }

              if (message.includes('auth/invalid-email')) {
                toast.error('Por favor verifique el email ingresado')
              } else if (message.includes('auth/user-not-found')) {
                toast.error('El email ingresado no se ha encontrado')
              } else if (message.includes('auth/wrong-password')) {
                toast.error('La contraseña es incorrecta')
              } else if (message.includes('auth/network-request-failed')) {
                toast.error('Por favor verifique su conexión de internet')
              } else {
                toast.error(message)
              }
              console.error('Visualizando el error ', error)
            }
          }}
          fullWidth
        >
          Iniciar sesión
        </ColorButton>
        <Link
          to="/resetpassword"
          style={{ color: 'white', textDecoration: 'none' }}
        >
          <ColorButton
            sx={{ mt: 4 }}
            fullWidth
          >
            Olvidó su contraseña?
          </ColorButton>
        </Link>
      </form>
    </div>
  )
}

export default SigninForm