import { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import CssBaseline from '@mui/material/CssBaseline'
import DashboardLayout from '../layouts/DashboardLayout'
import useAuth from '../../hooks/useAuth'
import {
  collection, getDocs, orderBy, query, db,
} from '../../config/firebaseConfig'
import FullPageLoader from '../../common/FullPageLoader'
import {
  Approved,
  FilledOut,
  InProgress,
  P10Docs,
  P11Docs,
  P12Docs,
  P13Docs,
  P15Docs,
  P6Docs,
  P7Docs,
  P8Docs,
  P9Docs,
} from './InfoCard'

const StartPage = () => {
  const {
    user,
  } = useAuth()
  const [dataIsLoading, setDataIsLoading] = useState(true)
  const [filledOutDocs, setFilledOutDocs]: any = useState([])
  const [approvedDocs, setApprovedDocs]: any = useState([])
  const [inProgressDocs, setInProgressDocs]: any = useState([])
  const [p6Docs, setp6Docs]: any = useState([])
  const [p7Docs, setp7Docs]: any = useState([])
  const [p8Docs, setp8Docs]: any = useState([])
  const [p9Docs, setp9Docs]: any = useState([])
  const [p10Docs, setp10Docs]: any = useState([])
  const [p11Docs, setp11Docs]: any = useState([])
  const [p12Docs, setp12Docs]: any = useState([])
  const [p13Docs, setp13Docs]: any = useState([])
  const [p15Docs, setp15Docs]: any = useState([])

  const getAllDocuments = async () => {
    const formsRef = collection(db, 'forms')

    try {
      const q = query(formsRef, orderBy('newFormCreatedAt', 'desc'))
      const querySnapshot = await getDocs(q)
      const allFilledOutPermits: any = []
      const allApprovedPermits: any = []
      const allInProgressPermits: any = []
      const allp6Permits: any = []
      const allp7Permits: any = []
      const allp8Permits: any = []
      const allp9Permits: any = []
      const allp10Permits: any = []
      const allp11Permits: any = []
      const allp12Permits: any = []
      const allp13Permits: any = []
      const allp15Permits: any = []

      querySnapshot.forEach((doc) => {
        if (
          doc?.data()?.newFormp1FilledOut
          && doc?.data()?.newFormp2FilledOut
          && doc?.data()?.newFormp3FilledOut
          && doc?.data()?.newFormp4FilledOut
          && doc?.data()?.newFormp5FilledOut
          && doc?.data()?.newFormp6FilledOut
          && doc?.data()?.newFormp7FilledOut
          && doc?.data()?.newFormp8FilledOut
          && doc?.data()?.newFormp9FilledOut
          && doc?.data()?.newFormp10FilledOut
          && doc?.data()?.newFormp11FilledOut
          && doc?.data()?.newFormp12FilledOut
          && doc?.data()?.newFormp13FilledOut
          && doc?.data()?.newFormp14FilledOut
          && doc?.data()?.newFormp15FilledOut
          && doc?.data()?.newFormp16FilledOut
        ) {
          allFilledOutPermits.push(doc?.data())
        }

        if (
          doc?.data()?.peopleApprovedAllPages.toString() === 'true') {
          allApprovedPermits.push('true')
        }

        if (
          (doc?.data()?.newFormp1FilledOut
            && doc?.data()?.newFormp2FilledOut
            && doc?.data()?.newFormp3FilledOut
            && doc?.data()?.newFormp4FilledOut
            && doc?.data()?.newFormp5FilledOut
            && doc?.data()?.newFormp6FilledOut
            && doc?.data()?.newFormp7FilledOut
            && doc?.data()?.newFormp8FilledOut
            && doc?.data()?.newFormp9FilledOut
            && doc?.data()?.newFormp10FilledOut
            && doc?.data()?.newFormp11FilledOut
            && doc?.data()?.newFormp12FilledOut
            && doc?.data()?.newFormp13FilledOut
            && doc?.data()?.newFormp14FilledOut
            && doc?.data()?.newFormp15FilledOut
            && doc?.data()?.newFormp16FilledOut)
          && (doc?.data()?.peopleApprovedAllPages.toString() === 'false')) {
          console.log('Filled out page 2 and not approved by people : ', doc?.data())

          allInProgressPermits.push('true')
        }

        if (doc?.data()?.newFormp1FilledOut
          && doc?.data()?.newFormp2FilledOut
          && doc?.data()?.newFormp3FilledOut
          && doc?.data()?.newFormp4FilledOut
          && doc?.data()?.newFormp5FilledOut
          && doc?.data()?.newFormp6FilledOut
          && doc?.data()?.newFormp7FilledOut
          && doc?.data()?.newFormp8FilledOut
          && doc?.data()?.newFormp9FilledOut
          && doc?.data()?.newFormp10FilledOut
          && doc?.data()?.newFormp11FilledOut
          && doc?.data()?.newFormp12FilledOut
          && doc?.data()?.newFormp13FilledOut
          && doc?.data()?.newFormp14FilledOut
          && doc?.data()?.newFormp15FilledOut
          && doc?.data()?.newFormp16FilledOut
          && doc?.data()?.p6Show) {
          console.log('Height job document : ', doc?.id)
          allp6Permits.push('true')
        }
        if (doc?.data()?.newFormp1FilledOut
          && doc?.data()?.newFormp2FilledOut
          && doc?.data()?.newFormp3FilledOut
          && doc?.data()?.newFormp4FilledOut
          && doc?.data()?.newFormp5FilledOut
          && doc?.data()?.newFormp6FilledOut
          && doc?.data()?.newFormp7FilledOut
          && doc?.data()?.newFormp8FilledOut
          && doc?.data()?.newFormp9FilledOut
          && doc?.data()?.newFormp10FilledOut
          && doc?.data()?.newFormp11FilledOut
          && doc?.data()?.newFormp12FilledOut
          && doc?.data()?.newFormp13FilledOut
          && doc?.data()?.newFormp14FilledOut
          && doc?.data()?.newFormp15FilledOut
          && doc?.data()?.newFormp16FilledOut
          && doc?.data()?.p7Show) {
          console.log('Confined document : ', doc?.id)
          allp7Permits.push('true')
        }
        if (doc?.data()?.newFormp1FilledOut
          && doc?.data()?.newFormp2FilledOut
          && doc?.data()?.newFormp3FilledOut
          && doc?.data()?.newFormp4FilledOut
          && doc?.data()?.newFormp5FilledOut
          && doc?.data()?.newFormp6FilledOut
          && doc?.data()?.newFormp7FilledOut
          && doc?.data()?.newFormp8FilledOut
          && doc?.data()?.newFormp9FilledOut
          && doc?.data()?.newFormp10FilledOut
          && doc?.data()?.newFormp11FilledOut
          && doc?.data()?.newFormp12FilledOut
          && doc?.data()?.newFormp13FilledOut
          && doc?.data()?.newFormp14FilledOut
          && doc?.data()?.newFormp15FilledOut
          && doc?.data()?.newFormp16FilledOut
          && doc?.data()?.p8Show) {
          console.log('Clean document : ', doc?.id)
          allp8Permits.push('true')
        }
        if (doc?.data()?.newFormp1FilledOut
          && doc?.data()?.newFormp2FilledOut
          && doc?.data()?.newFormp3FilledOut
          && doc?.data()?.newFormp4FilledOut
          && doc?.data()?.newFormp5FilledOut
          && doc?.data()?.newFormp6FilledOut
          && doc?.data()?.newFormp7FilledOut
          && doc?.data()?.newFormp8FilledOut
          && doc?.data()?.newFormp9FilledOut
          && doc?.data()?.newFormp10FilledOut
          && doc?.data()?.newFormp11FilledOut
          && doc?.data()?.newFormp12FilledOut
          && doc?.data()?.newFormp13FilledOut
          && doc?.data()?.newFormp14FilledOut
          && doc?.data()?.newFormp15FilledOut
          && doc?.data()?.newFormp16FilledOut
          && doc?.data()?.p9Show) {
          console.log('Izaje document : ', doc?.id)
          allp9Permits.push('true')
        }
        if (doc?.data()?.newFormp1FilledOut
          && doc?.data()?.newFormp2FilledOut
          && doc?.data()?.newFormp3FilledOut
          && doc?.data()?.newFormp4FilledOut
          && doc?.data()?.newFormp5FilledOut
          && doc?.data()?.newFormp6FilledOut
          && doc?.data()?.newFormp7FilledOut
          && doc?.data()?.newFormp8FilledOut
          && doc?.data()?.newFormp9FilledOut
          && doc?.data()?.newFormp10FilledOut
          && doc?.data()?.newFormp11FilledOut
          && doc?.data()?.newFormp12FilledOut
          && doc?.data()?.newFormp13FilledOut
          && doc?.data()?.newFormp14FilledOut
          && doc?.data()?.newFormp15FilledOut
          && doc?.data()?.newFormp16FilledOut
          && doc?.data()?.p10Show) {
          console.log('Chemical usage document : ', doc?.id)
          allp10Permits.push('true')
        }
        if (doc?.data()?.newFormp1FilledOut
          && doc?.data()?.newFormp2FilledOut
          && doc?.data()?.newFormp3FilledOut
          && doc?.data()?.newFormp4FilledOut
          && doc?.data()?.newFormp5FilledOut
          && doc?.data()?.newFormp6FilledOut
          && doc?.data()?.newFormp7FilledOut
          && doc?.data()?.newFormp8FilledOut
          && doc?.data()?.newFormp9FilledOut
          && doc?.data()?.newFormp10FilledOut
          && doc?.data()?.newFormp11FilledOut
          && doc?.data()?.newFormp12FilledOut
          && doc?.data()?.newFormp13FilledOut
          && doc?.data()?.newFormp14FilledOut
          && doc?.data()?.newFormp15FilledOut
          && doc?.data()?.newFormp16FilledOut
          && doc?.data()?.p11Show) {
          console.log('Maintenance ssgg document : ', doc?.id)
          allp11Permits.push('true')
        }
        if (doc?.data()?.newFormp1FilledOut
          && doc?.data()?.newFormp2FilledOut
          && doc?.data()?.newFormp3FilledOut
          && doc?.data()?.newFormp4FilledOut
          && doc?.data()?.newFormp5FilledOut
          && doc?.data()?.newFormp6FilledOut
          && doc?.data()?.newFormp7FilledOut
          && doc?.data()?.newFormp8FilledOut
          && doc?.data()?.newFormp9FilledOut
          && doc?.data()?.newFormp10FilledOut
          && doc?.data()?.newFormp11FilledOut
          && doc?.data()?.newFormp12FilledOut
          && doc?.data()?.newFormp13FilledOut
          && doc?.data()?.newFormp14FilledOut
          && doc?.data()?.newFormp15FilledOut
          && doc?.data()?.newFormp16FilledOut
          && doc?.data()?.p12Show) {
          console.log('Good practice document : ', doc?.id)
          allp12Permits.push('true')
        }
        if (doc?.data()?.newFormp1FilledOut
          && doc?.data()?.newFormp2FilledOut
          && doc?.data()?.newFormp3FilledOut
          && doc?.data()?.newFormp4FilledOut
          && doc?.data()?.newFormp5FilledOut
          && doc?.data()?.newFormp6FilledOut
          && doc?.data()?.newFormp7FilledOut
          && doc?.data()?.newFormp8FilledOut
          && doc?.data()?.newFormp9FilledOut
          && doc?.data()?.newFormp10FilledOut
          && doc?.data()?.newFormp11FilledOut
          && doc?.data()?.newFormp12FilledOut
          && doc?.data()?.newFormp13FilledOut
          && doc?.data()?.newFormp14FilledOut
          && doc?.data()?.newFormp15FilledOut
          && doc?.data()?.newFormp16FilledOut
          && doc?.data()?.p13Show) {
          console.log('Hot work document : ', doc?.id)
          allp13Permits.push('true')
        }
        if (doc?.data()?.newFormp1FilledOut
          && doc?.data()?.newFormp2FilledOut
          && doc?.data()?.newFormp3FilledOut
          && doc?.data()?.newFormp4FilledOut
          && doc?.data()?.newFormp5FilledOut
          && doc?.data()?.newFormp6FilledOut
          && doc?.data()?.newFormp7FilledOut
          && doc?.data()?.newFormp8FilledOut
          && doc?.data()?.newFormp9FilledOut
          && doc?.data()?.newFormp10FilledOut
          && doc?.data()?.newFormp11FilledOut
          && doc?.data()?.newFormp12FilledOut
          && doc?.data()?.newFormp13FilledOut
          && doc?.data()?.newFormp14FilledOut
          && doc?.data()?.newFormp15FilledOut
          && doc?.data()?.newFormp16FilledOut
          && doc?.data()?.p15Show) {
          console.log('Energy block document : ', doc?.id)
          allp15Permits.push('true')
        }
      })

      console.log('1-all the filled out permits length : ', allFilledOutPermits.length)
      console.log('1-all the Approvedpermits length : ', allApprovedPermits.length)
      console.log('1-all the In Progress permits length : ', allInProgressPermits.length)

      setFilledOutDocs(allFilledOutPermits)
      setApprovedDocs(allApprovedPermits)
      setInProgressDocs(allInProgressPermits)
      setp6Docs(allp6Permits)
      setp7Docs(allp7Permits)
      setp8Docs(allp8Permits)
      setp9Docs(allp9Permits)
      setp10Docs(allp10Permits)
      setp11Docs(allp11Permits)
      setp12Docs(allp12Permits)
      setp13Docs(allp13Permits)
      setp15Docs(allp15Permits)

      setDataIsLoading(false)
    } catch (error) {
      console.log('Error : ', error)
    }
  }

  useEffect(() => {
    getAllDocuments()
  }, [])

  return (
    <>
      <DashboardLayout>
        {dataIsLoading || !user ? <FullPageLoader /> : (
          <>
            <CssBaseline />
            <Container
              maxWidth="md"
              sx={{ mt: 4, mb: 4 }}
            >
              <Grid container spacing={5}>
                <Grid item xs={12} md={8} lg={12}>
                  <Typography variant="subtitle1" gutterBottom>
                    {`Bienvenido de vuelta, ${user?.email}! Estas son las últimas actualizaciones:`}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FilledOut filledOutDocs={filledOutDocs} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Approved approvedDocs={approvedDocs} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InProgress inProgressDocs={inProgressDocs} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <P6Docs p6Docs={p6Docs} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <P7Docs p7Docs={p7Docs} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <P8Docs p8Docs={p8Docs} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <P9Docs p9Docs={p9Docs} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <P10Docs p10Docs={p10Docs} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <P11Docs p11Docs={p11Docs} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <P12Docs p12Docs={p12Docs} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <P13Docs p13Docs={p13Docs} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <P15Docs p15Docs={p15Docs} />
                </Grid>
              </Grid>
            </Container>
          </>
        )}
      </DashboardLayout>
    </>
  )
}

export default StartPage