import Switch from '@mui/material/Switch'
import { styled } from '@mui/material/styles'

// eslint-disable-next-line
const YellowSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: 'white',
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#fbc02d',
  },
}))

export default YellowSwitch