const allUsers = [
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'stefani.cataldo@pepsico.com',
    rolName: 'cerrillos',
    userKey: 'S9J4zoCOZEMFjnhSBb2nOWm7EEB2',
    userName: 'Stefani Cataldo',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'jonathanjosue.fuentesplaza@pepsico.com',
    rolName: 'cerrillos',
    userKey: 'IqLtXijPE8gjUsjsdncwFcVWofP2',
    userName: 'Jonathan Fuentes',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'nicolas.altamirano@pepsico.com',
    rolName: 'cerrillos',
    userKey: '8uec78JvtEc4mSZc2kpuVasfWAX2',
    userName: 'Nicolas Altamirano',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'aaron.calderon.contractor@pepsico.com',
    rolName: 'cerrillos',
    userKey: 'j5ZHSzwXm0XHRfLGy5Grz6TwiuA2',
    userName: 'Aaron Calderon',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'kevin.toledo@pepsico.com',
    rolName: 'cerrillos',
    userKey: 'iFkd2ni7FxghXg8nhRNwqFngbN33',
    userName: 'Kevin Toledo',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'lobato.mariluzc21@gmail.com',
    rolName: 'cerrillos',
    userKey: 'f8C1GZVplshJC4dClZlrDdBik8K2',
    userName: 'Mariluz Lobato',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'pedromarcelo2@gmail.com',
    rolName: 'cerrillos',
    userKey: 'htm50oKeuITHSwaZIQF8Ql6MFrk1',
    userName: 'Pedro Vergara',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'GIOVANNI.OPAZOMEDINA.Contractor@pepsico.com',
    rolName: 'patrimonialSecurity',
    userKey: '16SwOPVpaBUzOiEU2ZboXUXOGiB2',
    userName: 'Giovanni Opazo',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'PERLAANDREA.URRIOLAARAOS@pepsico.com',
    rolName: 'quality',
    userKey: '6gfxV69mafVc7GdyRiFB8zynP4q2',
    userName: 'Perla Urriola',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'GERARDO.VASQUEZ1@pepsico.com',
    rolName: 'technicalArea',
    userKey: 'iILnLSVSEvOly3MY0GFcdTEq7Cs1',
    userName: 'Gerardo Vasquez',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'VICTORALEJANDRO.HURTADOSANDOVAL@pepsico.com',
    rolName: 'production',
    userKey: 'cMIln0n3zYgLpG97dUpLvq3bS3C3',
    userName: 'Victor Hurtado',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'Brenda.Illanes@pepsico.com',
    rolName: 'secondaryFleet',
    userKey: 'k0UhdESzbfZg2aEOwQlXPJyCUPo1',
    userName: 'Brenda Illanes',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'luis.nunez1@pepsico.com',
    rolName: 'production',
    userKey: 'rT2lORo6SnQvRY8lzSN3wDlZLIK2',
    userName: 'Luis Nuñez',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'BEATRIZ.CARABANO@pepsico.com',
    rolName: 'technicalArea',
    userKey: 'bW6dgycVdtP81mULDWW3WKi9FPt1',
    userName: 'Beatriz Carabano',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'karla.castro1@cbre.com',
    rolName: 'cerrillos',
    userKey: 't93UVspI1deShGSKr7yNtcga8KH3',
    userName: 'Karla Castro',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'JUANDIEGO.VALEBELTRAN@pepsico.com',
    rolName: 'operations',
    userKey: 'JaIQ3KiUNybtdQwmL9rMzLt8mmr2',
    userName: 'Juan Vale',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'roberto.quintero@pepsico.com',
    rolName: 'production',
    userKey: 'T3s4bOqDjXToc3VSqhIJ5CBCZZi1',
    userName: 'Roberto Quintero',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'raul.moralesmunoz@pepsico.com',
    rolName: 'patrimonialSecurity',
    userKey: 'kIORk1UAh6c89CdQvNOAFJu47nE3',
    userName: 'Raul Morales',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'Cesar.Moreira@pepsico.com',
    rolName: 'technicalArea',
    userKey: 'qYlVPy0XiDWElZrmDCSTTsENeCL2',
    userName: 'Cesar Moreira',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'lreyes@climacor.cl',
    rolName: 'cerrillos',
    userKey: 'I9UjIopWDVV4i6DuqKgeCMbl86p2',
    userName: 'Luis Reyes',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'ITALO.VEGABRICENO@pepsico.com',
    rolName: 'technicalArea',
    userKey: 'ffWVuzRxYAVvLfGLbpO8lX98vo92',
    userName: 'Italo Vega',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'jsanchez@ecoriles.cl',
    rolName: 'cerrillos',
    userKey: 'cqd7V7TKvCRpWsX9RtJgd2RZpgt1',
    userName: 'Jose Sanchez',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'gregorio.cardoza@pepsico.com',
    rolName: 'technicalArea',
    userKey: 'EVL05bFWgUcearLlluJThc1GnF83',
    userName: 'Gregorio Cardoza',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'ramon.angulo@pepsico.com',
    rolName: 'engineering',
    userKey: 'UIOUjDwjTtf2H6zCuJkaPOxLWwP2',
    userName: 'Ramon Angulo',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'nlb@conflutec.cl',
    rolName: 'cerrillos',
    userKey: '8UzV1ms0wyTfAlKv2m4B9j5INf43',
    userName: 'Nicolas Laurito',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'Yuri.Cristino@pepsico.com',
    rolName: 'production',
    userKey: 'CKJxjN17UMgnY21CtjnvIxyaFR12',
    userName: 'Yuri Cristino',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'lucia.mardones@pepsico.com',
    rolName: 'sass',
    userKey: 'QJxTXd7mHghpL1crOfxvFm1NZmh1',
    userName: 'Lucia Mardones',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'debora.munoz@pepsico.com',
    rolName: 'production',
    userKey: '2GdPBpAFCEfgz8MgJCCxvPINqnB2',
    userName: 'Debora Muñoz',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'RICARDO.TORRESVARGAS@pepsico.com',
    rolName: 'quality',
    userKey: 'IaNcFdGdZCcxAkUMQT3ckHwdncR2',
    userName: 'Ricardo Torres',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'hector.pedraza@cbre.com',
    rolName: 'cerrillos',
    userKey: 'kBoyvePWKIPZ6uiftwUWSY6HhWR2',
    userName: 'Hector Pedraza',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'prevencionista@mingatec.com',
    rolName: 'cerrillos',
    userKey: 'KlzbY85J75fGdgnQTjMHRLrNTGh1',
    userName: 'Karen Saldias',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'gustavo.gomez@ryggraficas.cl',
    rolName: 'cerrillos',
    userKey: '0aEJwZTgowd1FYLsOjFMEJ8EwZm2',
    userName: 'Gustavo Gomez',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'guillermo.fuentes@pepsico.com',
    rolName: 'agricultural',
    userKey: 'YDeJUDilP7h9ZOAB5yiG4JMQZUy2',
    userName: 'Guillermo Fuentes',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'Florines.Meneses.Contractor@pepsico.com',
    rolName: 'quality',
    userKey: 'iOAWngGgJdaG4tU9lVUVqqjLUtG2',
    userName: 'Florines Meneses',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'PAMELA.ORELLANA@pepsico.com',
    rolName: 'quality',
    userKey: 'nE1qYxIwBzbhaMg6epcgTCAFdS33',
    userName: 'Pamela Orellana',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'eric.berrios@pepsico.com',
    rolName: 'administration',
    userKey: 'B4jV3SpUGmb4vT5jITD8SEGNX5A3',
    userName: 'Eric Berrios',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'patino-gabriel@aramark.cl',
    rolName: 'cerrillos',
    userKey: 'nRtuOh9nv1fdTmHKNwGdKPFdxDK2',
    userName: 'Gabriel Patiño',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'ivan.reyes@tecsam.cl',
    rolName: 'cerrillos',
    userKey: 'B0FebmG7kZbmKvvQp2VLpzRsweJ2',
    userName: 'Ivan Reyes',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'freyes@ingemess.cl',
    rolName: 'cerrillos',
    userKey: 'WKILmbuQmHXQ499NwMetqBYMbvU2',
    userName: 'Francisca Reyes',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'SERGIO.GUAJARDO@pepsico.com',
    rolName: 'technicalArea',
    userKey: 'xxpqsE1rgVORUdkqAAaCzN5vVNr2',
    userName: 'Sergio Guajardo',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'MATIAS.LIRAWATKINS@pepsico.com',
    rolName: 'sass',
    userKey: 'D6iXBu9P0kYeWZlzSir3Ohww1Ff2',
    userName: 'Matias Lira',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'ALISONFERNANDA.BARRAZAMOROSO@pepsico.com',
    rolName: 'unitPickUp',
    userKey: 'IKJpjIK3ceaEBpV4KIjB1FtpWsI2',
    userName: 'Alison Barraza',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'Luis.PobletePacheco@pepsico.com',
    rolName: 'agricultural',
    userKey: 'spDpfTkW2PZm2yKVGsqTwdSiZoY2',
    userName: 'Luis Poblete',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'JESUS.INFANTEMIRANDA@pepsico.com',
    rolName: 'technicalArea',
    userKey: 'Nz5vKm6Um9ZZcmOXp8p8CGcM73l2',
    userName: 'Jesus Infante',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'richard.flores@pepsico.com',
    rolName: 'logistics',
    userKey: 'WKezh1HV3IUzcDjehl7uZf7NQHR2',
    userName: 'Richard Flores',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'mariana.lemus@pepsico.com',
    rolName: 'sass',
    userKey: 'SJ59ObQ38mZZ5kXUmPN7l1b04N02',
    userName: 'Mariana Lemus',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'Juan.MartinezCaceres@pepsico.com',
    rolName: 'boxPickup',
    userKey: 'cWLBv4lTSMNZcd7rt9qCPtr8ExY2',
    userName: 'Juan Martines',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'RAULALEXANDER.OSORIOMAESTRE@pepsico.com',
    rolName: 'quality',
    userKey: 'ouSUVBC3C5czyyOAAt3e8PGGqfe2',
    userName: 'Raul Osorio',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'Ericka.Lopez@pepsico.com',
    rolName: 'generalServices',
    userKey: '82KTJd3nlyaeEACc8WYfqnp2I3V2',
    userName: 'Ericka Lopez',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'cifuentes-victor@aramark.com',
    rolName: 'mcs',
    userKey: 'brPxTvXkfnUxURjdzT5gqy8zrr22',
    userName: 'Victor Cifuentes',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'marlite.torres@pepsico.com',
    rolName: 'sass',
    userKey: 'GtDdJPjZ8sUZR1vdXk8htSG8ycN2',
    userName: 'Marlite Torres',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'camilo.germanhuerta@gmail.com',
    rolName: 'cerrillos',
    userKey: 'AibPraDzo3Va0pwMUTnLHSXEbgm2',
    userName: 'Camilo Huerta',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'Daiana.REEBERG@pepsico.com',
    rolName: 'RR.HH',
    userKey: 'LDvD5rU5mTWvYZek7wkF1iCNQmK2',
    userName: 'Daiana Reeberg',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'CarlosOsvaldo.Torres@pepsico.com',
    rolName: 'technicalArea',
    userKey: '8RMq0UWaTJY5OU4TfJXE13ivZ9Y2',
    userName: 'Carlos Torres',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'JAIMESEBASTIAN.FONSECAMARCHANT@pepsico.com',
    rolName: 'sass',
    userKey: 'ziObrv7ctgUsomFvC95GIscvjEb2',
    userName: 'Jaime Fonseca',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'Alejandro.AlfaroAlvarado.Contractor@pepsico.com',
    rolName: 'engineering',
    userKey: 'p1HCLTatHQRvJU54Phw9dvGHp1C3',
    userName: 'Alejandro Alfaro',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'MAVILACATALINA.SUAREZCONSTANZO1@pepsico.com',
    rolName: 'quality',
    userKey: '3JC1Ep8GfhXVKeOHxn2Ne71gNZl1',
    userName: 'Mavila Suarez',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'Astudilloprevencionista@gmail.com',
    rolName: 'cerrillos',
    userKey: 'TnBdgtD0jGf5076dMmrRzh6mTC32',
    userName: 'Luis Astudillo',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'lschmeisser@icmant.cl',
    rolName: 'cerrillos',
    userKey: 'ufeTnODTUkWRMUdmkkOaN6pgk3I3',
    userName: 'Luis Schmeisser',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'german.lazo@pepsico.com',
    rolName: 'technicalArea',
    userKey: 'CtCSlNqu86RjYY1A1gqYKvxtMVo2',
    userName: 'German Lazo',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'jmarchant35@gmail.com',
    rolName: 'cerrillos',
    userKey: 'rzhzsfh6XNV67LbtEcNI6NDpBd63',
    userName: 'Joan Marchant',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'eklausen@ingemess.cl',
    rolName: 'cerrillos',
    userKey: 'zhTC2g78IngkyKGtB7ATTEbuGOj1',
    userName: 'Estefania Klausen',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'yesenia.morales@pepsico.com',
    rolName: 'sass',
    userKey: 'aOVTsxZNwQW6QNRyFgojO074iwC2',
    userName: 'Yesenia Morales',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'DIEGOALFONSO.DIAZMORAGA@pepsico.com',
    rolName: 'loadingAndUnloading',
    userKey: 'ISPoUPxQo7PFbx8slNaIdKXJrBe2',
    userName: 'Diego Diaz',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'SARAITHELOISA.TELLECHEAGUTIERREZ@pepsico.com',
    rolName: 'quality',
    userKey: 'rzH1L2dMHDRDU7IkAGwNeeOVwC53',
    userName: 'Sarai Tellechea',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'LeidyLeomary.SanchezRamirez@pepsico.com',
    rolName: 'quality',
    userKey: '5OfCMAFtEyW01Cr0ndjyjmoWX9w2',
    userName: 'Leidy Sanchez',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'antonio.castillo@pepsico.com',
    rolName: 'quality',
    userKey: '7Clb78z0gWNujQ5srEhFeNSuIfH3',
    userName: 'Antonio Castillo',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'KENNYJOSE.CORDOBAAREVALO@pepsico.com',
    rolName: 'logistics',
    userKey: 'DYtLou6VLaWAwNV7HPoHYQZjGDs2',
    userName: 'Kenny Cordoba',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'JOSEANTONIO.RIVASSUAREZ@pepsico.com',
    rolName: 'administration',
    userKey: 'CuNTeZC5W7R3MQnw2PN6ayPrmR02',
    userName: 'Jose Rivas',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 's.cataldoramos@gmail.com',
    rolName: 'sass',
    userKey: '9MvTXZMycHc0mfRPhsfHVkMrMAw1',
    userName: 'Stefani Cataldo',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'ANDREADELCARMEN.LULIONCASTILLO@pepsico.com',
    rolName: 'quality',
    userKey: 'Ekfo996qD0XkjGKfZLoxFzCw1t93',
    userName: 'Andrea Lulion',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'William.Merino@pepsico.com',
    rolName: 'quality',
    userKey: 'SdCDlBmpICP45QlBfkZm9GwIviu1',
    userName: 'William Merino',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'CESARENRIQUE.SALASCARRERO@pepsico.com',
    rolName: 'sass',
    userKey: '0g85M2QTKBSfgIm19MfFK6msdl63',
    userName: 'Cesar Salas',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'Nahum.Barrueto@pepsico.com',
    rolName: 'patrimonialSecurity',
    userKey: 'iBPWGnHakMcbMKQY8WQPthQ130Q2',
    userName: 'Nahum Barrueto',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'franz.torrellas@pepsico.com',
    rolName: 'technicalArea',
    userKey: '0CBxYE8GxUZOlLJk9wsjLlVIe382',
    userName: 'Franz Torrellas',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'NICOLAS.DUQUES@pepsico.com',
    rolName: 'technicalArea',
    userKey: '2PfcT2Hl3RWf7ZgiGNCxlLfK9eq1',
    userName: 'Nicolas Duques',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'karladelvalle.caraballo@pepsico.com',
    rolName: 'agricultural',
    userKey: 'H9fSpkvINSYhqVD6PArJMFgnP463',
    userName: 'Karla Caraballo',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'HEINSTEINWROWER.BARRERACONTRERAS@pepsico.com',
    rolName: 'quality',
    userKey: 'MgkZCadkhhSNuktRzm5E8EjXVI82',
    userName: 'Heinstein Barrera',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'alejandro.lillo@pepsico.com',
    rolName: 'technicalArea',
    userKey: 'I6njXcH9g8P67MHgVa8p8TzFX872',
    userName: 'Alejandro Lillo',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'JOSEANGEL.PULIDOCOLINA1@pepsico.com',
    rolName: 'quality',
    userKey: '4U24kze4rUcBalGvyxMsZkrv43e2',
    userName: 'Jose Pulido',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'polievercrisp@hotmail.cl',
    rolName: 'Esachs',
    userKey: 'zvRk91O1sXRL7OU2huVUQPzQRZI3',
    userName: 'Paramedicos',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'bonillo-ruben@aramark.cl',
    rolName: 'cerrillos',
    userKey: 'HFXCWdA8kOROw01Ye9b5aO3DuqE2',
    userName: 'Ruben Bonillo',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'GUSTAVODIOXOMBERG.BRACAMONTEORELLANO@pepsico.com',
    rolName: 'technicalArea',
    userKey: 'bmn18uwMnlYp1Ftl69xj0uG9Kje2',
    userName: 'Gustavo Bracamonte',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'jaime.lienan@pepsico.com',
    rolName: 'production',
    userKey: 'yWkz2MaVcJXHATYU2ldyZwjYrJJ3',
    userName: 'Jaime Lienan',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'MICHELANDRES.RIVERAAGUILERA@pepsico.com',
    rolName: 'production',
    userKey: 'euq9ochGpxUSA3iB0nzVy6Qczqy2',
    userName: 'Michel Rivera',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'paula.avello@pepsico.com',
    rolName: 'production',
    userKey: '3tPt6Mg0soZXIhfV4JCbLmW9Qdt2',
    userName: 'Paula Avello',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'MIGUEL.ALVARADOMOLINA@pepsico.com',
    rolName: 'Mantencion',
    userKey: 'UbXkVfQMGQNARRWiJp2hUQ6DwvM2',
    userName: 'Miguel Alvarado',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'cpalacios@climacor.cl',
    rolName: 'cerrillos',
    userKey: 'Lw9GdlJC6mYopUECxUXTm3rBihj2',
    userName: 'Camila Palacios',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'jimmy.ortega@pepsico.com',
    rolName: 'production',
    userKey: 'QL0DGi2UtoRLhx3kNeErhkB4OpN2',
    userName: 'Jimmy Ortega',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'prevencionista@inox-man.cl',
    rolName: 'cerrillos',
    userKey: 'JETQcQi6YZR2NFBn2IpW9gNWIvl1',
    userName: 'Almendra Riquelme',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'marisela.jimenez@tecsam.cl',
    rolName: 'cerrillos',
    userKey: '0t9yMPQNJHTJoZxZ43x9kJdGDNW2',
    userName: 'Marisela Jimenez',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'gustavo.lorca@pepsico.com',
    rolName: 'logistics',
    userKey: 'LS9VCoiyuwhZKRxLUadSntfmNRq1',
    userName: 'Gustavo Lorca',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'FABIAN.RAMIREZVIVANCO@pepsico.com',
    rolName: 'technicalArea',
    userKey: 'iCBvMBpuKeShJ5GEwlXAe2OapUN2',
    userName: 'Fabian Ramirez',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'luciano.bobadilla@pepsico.com',
    rolName: 'unitPickUp',
    userKey: 'pjxzuL97dbWcAAoxDAfd26YLIts1',
    userName: 'Luciano Bobadilla',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'pormeno@genesismi.cl',
    rolName: 'cerrillos',
    userKey: 'EBna8aB067T7BqD27WtKIUdTlXr2',
    userName: 'Pedro Ormeño',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'luis.figueroa1@pepsico.com',
    rolName: 'technicalArea',
    userKey: 'VfAUFtKicJZzijEDDiW3JQUNDUs1',
    userName: 'Luis Figueroa',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'rorellana@climacor.cl',
    rolName: 'cerrillos',
    userKey: 'ktBZx9m4jVdjvBqovSY2f801CxC2',
    userName: 'Rodrigo Orellana',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'Nicolas.Oportus.Contractor@pepsico.com',
    rolName: 'quality',
    userKey: 'Vam4mXggRDY4PSgZKbftbUj02sH2',
    userName: 'Nicolas Oporto',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'jose.tapia@pepsico.com',
    rolName: 'operations',
    userKey: 'QIFqBBugB4YAViDuuFQPfGZqfgG2',
    userName: 'Jose Tapia',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'cesar.kuncar@pepsico.com',
    rolName: 'technicalArea',
    userKey: 'EbRD2TdiRWbtV8NnqmIFXk0NXxF3',
    userName: 'Cesar Kuncar',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'Hugo.RiveraFica@pepsico.com',
    rolName: 'technicalArea',
    userKey: 'YJqaNJUqzRVipVkFMPXiaONTSSa2',
    userName: 'Hugo Rivera',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'JUANJOSE.PALMERATIMAURE@pepsico.com',
    rolName: 'unitPickUp',
    userKey: 'qFc6vh431OaUkcvxKI4j1abxldV2',
    userName: 'Juan Palmera',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'VICTORANDRES.CHAVEZALLENDE@pepsico.com',
    rolName: 'technicalArea',
    userKey: 'Vx2ubTtENCVOtFdlzomfo1kwX6r1',
    userName: 'Victor Chavez',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'CHARLYJHONATHAN.QUINTEROHIDALGO@pepsico.com',
    rolName: 'logistics',
    userKey: 'AMwxrB0X5jXcvodYpKwwjtljlMk2',
    userName: 'Charli Quintero',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'juan.abarca@pepsico.com',
    rolName: 'quality',
    userKey: '4OiGdF1Qh0e43iuR0Goo220vhZy2',
    userName: 'Juan Abarca',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'hormeno@genesismi.cl',
    rolName: 'cerrillos',
    userKey: 'j1oOTz9HuWerjYLilDUlgRlKCEp1',
    userName: 'Hugo Ormeño',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'juan.paillan@pepsico.com',
    rolName: 'patrimonialSecurity',
    userKey: 'JsnijR6P5jYk1OnFLNX7ZKQDh672',
    userName: 'Juan Paillan',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'gonzalo.morgado@pepsico.com',
    rolName: 'technicalArea',
    userKey: 'ilCMcWjIIGWibwQF5mdycriHDcB3',
    userName: 'Gonzalo Morgado',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'Carolina.Anabalon.Contractor@pepsico.com',
    rolName: 'quality',
    userKey: '59BYsQcLZ4ZQDRp5FwYK9tFDZR63',
    userName: 'Carolina Anabalon',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'mardomingo-maria@aramark.cl',
    rolName: 'cerrillos',
    userKey: '2vdfZkcxo8SXmVkG8fKxEBB5jt93',
    userName: 'Maria Julia Mardomingo',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'JoseGregorio.PachecoSalcedo@pepsico.com',
    rolName: 'quality',
    userKey: '5DPEM2eri2Pt060SiEbTQGvCuYt1',
    userName: 'Jose Pacheco',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'nicolas.cartagena@pepsico.com',
    rolName: 'quality',
    userKey: 'CrzL0C2JkdSsh720Mopw771QD1x2',
    userName: 'Nicolas Cartagena',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'pablocesar.cruz@pepsico.com',
    rolName: 'logistics',
    userKey: 'PZcrgvmJy9aMEvhlBJi1VtgA5g23',
    userName: 'Pablo Cruz',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'Angel.ValladaresRomero@pepsico.com',
    rolName: 'production',
    userKey: 'XOqW69V6VPWVMhMXwTtoCCzyEzt1',
    userName: 'Angel Valladares',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'paola.candia@pepsico.com',
    rolName: 'quality',
    userKey: '19SW2tpbZthzALUnPQN5wF7WAeC2',
    userName: 'Paola Candia ',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'naltamirano.l@gmail.com',
    rolName: 'sass',
    userKey: 'FI98jy4JtsQu9pBevQniWf5p3ct2',
    userName: 'Nicolas Altamirano',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'mauricio.andrade@pepsico.com',
    rolName: 'production',
    userKey: 'jMbUSLN1LueCb8xhFr3IYKQYRlC3',
    userName: 'Mauricio Andrade',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'mzuniga@genesismi.cl',
    rolName: 'cerrillos',
    userKey: 'LGYmEbSUwARzhISsJfNB7VG7fie2',
    userName: 'Miguel Zuñiga',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'isauris.rodriguez@pepsico.com',
    rolName: 'logistics',
    userKey: 'vFJVRZWkOxdJdmhdt3fsDQaHCn93',
    userName: 'Isauris Rodriguez',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'caldyquem@gmail.com',
    rolName: 'cerrillos',
    userKey: 'hbzu14qVKKXaRnNjlP08NSGqOrw1',
    userName: 'Paulina San Cristobal',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'mauricio.luna@pepsico.com',
    rolName: 'quality',
    userKey: 'XEd0jE4dlePvkJL1OTbgrqAL3hB2',
    userName: 'Mauricio Luna',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'luis.cuellar@pepsico.com',
    rolName: 'engineering',
    userKey: 'ab7zvv7LUWPzjax3wOaWv3r3Kf72',
    userName: 'Luis Cuellar',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'elvis.puebla1@pepsico.com',
    rolName: 'bli',
    userKey: 'jh41kzIsIShgT8QABwAjrpk0TTx1',
    userName: 'Elvis Puebla',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'drjohncabrera@gmail.com',
    rolName: 'sass',
    userKey: 'MYXW4y4JAUXqIMLleZP12PEb0O63',
    userName: 'John Cabrera',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'contrerasrodrigo20@gmail.com',
    rolName: 'cerrillos',
    userKey: 'HRGRm3J6zMgI7jPdsYNTMg52wzE2',
    userName: 'Rodrigo Contreras',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'rosa.moraga@pepsico.com',
    rolName: 'bli',
    userKey: '2ycJih2MgwQsT9zmI3ZS7Eoq5Ao1',
    userName: 'Rosa Moraga',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'oscar.diaz@pepsico.com',
    rolName: 'loadingAndUnloading',
    userKey: 'TINeqvpVwlbELLdoLlANdzxUcu43',
    userName: 'Oscar Diaz',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'MARCELAALEJANDRA.ATABALESJORQUERA@pepsico.com',
    rolName: 'bli',
    userKey: 'obKpP6PsvLOMLgUYAk25Cb4gkOv2',
    userName: 'Marcela Atabales',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'rubenosbaldo.diazcontreras@pepsico.com',
    rolName: 'Flota Primaria',
    userKey: 'ta4m6PebtIaLMh7xk3kQObLJJyf2',
    userName: 'Ruben Diaz',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'sandra.vera@pepsico.com',
    rolName: 'boxPickup',
    userKey: 'BEeFsqVQdCMS6iOF4Kv1X1sLNfp2',
    userName: 'Sandra Vera',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'GERALDOROBINSON.BURGOSVALENZUELA@pepsico.com',
    rolName: 'production',
    userKey: 'Ob9knryxIdSXlaCWRTNcMdVDGWd2',
    userName: 'Geraldo Burgos',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'jose.diaz@pepsico.com',
    rolName: 'production',
    userKey: 'l5Xy6uyCsZY6OfhUDfZfgzA6PfU2',
    userName: 'Jose Diaz',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'Edison.Carroza@pepsico.com',
    rolName: 'production',
    userKey: 'IK0SsniYcqUzHAqof2yowRggfLo2',
    userName: 'Edison Carroza',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'ISAACHUGO.VALENZUELAGONZALEZ@pepsico.com',
    rolName: 'production',
    userKey: 'nT3TcBb77IgTT8V5nh7UBDnPxdI3',
    userName: 'Isaac Valenzuela',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'felipe.rojas1@pepsico.com',
    rolName: 'operations',
    userKey: 'EEuMsRhUSpN3KG0Q8wFktNwKpPf1',
    userName: 'Felipe Rojas',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'Aran.Rubilar@pepsico.com',
    rolName: 'technicalArea',
    userKey: 'pjs6w7XIdmSS9MPLPAGkkqAAjJw1',
    userName: 'Aran Rubilar',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'sebastian.vasquez@mingatec.com',
    rolName: 'cerrillos',
    userKey: 'sEtcJsHIblVFlS70aSB4PQORC3l1',
    userName: 'Sebastian Vasquez',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'SEBASTIAN.FERNANDEZARAYA@pepsico.com',
    rolName: 'technicalArea',
    userKey: '43sQouyOerTzzSD4p8BhypqpwG42',
    userName: 'Sebastian Fernandez',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'ESTEBAN.ARCE@pepsico.com',
    rolName: 'technicalArea',
    userKey: 'HEjDjG5DWxPr0EGTeA6Zv8Sz7xk1',
    userName: 'Esteban Arce',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'sergiobc41@gmail.com',
    rolName: 'cerrillos',
    userKey: 'KuXUQkvmMiS8iBLG3OQNtLS8pK42',
    userName: 'Sergio Briones',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'ulises.reyes@ryggraficas.cl',
    rolName: 'cerrillos',
    userKey: 'Q4DRR5nq0SUovd1nisBwuamRQfC3',
    userName: 'Ulises Reyes',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'operacion@mingatec.com',
    rolName: 'cerrillos',
    userKey: 'icLTRgAK85NyE4dN9ql0ctC0RMQ2',
    userName: 'Carlos Chavez',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'DIEGOIGNACIO.NEIRAROCUANT@pepsico.com',
    rolName: 'sass',
    userKey: 'qNaGcdkV74ft1PptYcBrSqCWB0P2',
    userName: 'Diego Neira',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'Nicolas.Lagos.Contractor@pepsico.com',
    rolName: 'quality',
    userKey: 'evo4EneEIyfAWNwklCJi01IZWo62',
    userName: 'Nicolas Lagos',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'FRANCISCAGABRIELA.CATTANEOVALENZUELA@pepsico.com',
    rolName: 'motor',
    userKey: 'nqRHQQXRNFgl4jhMUlaY3HfY0Kh1',
    userName: 'Francisca Cataneo',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'mvargasc@ecoriles.cl',
    rolName: 'cerrillos',
    userKey: 'qlUhY0BIzVOHev1DnQo4jvIfMjp1',
    userName: 'Mauricio Vargas',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'jeannette.soto@pepsico.com',
    rolName: 'logistics',
    userKey: 'TcbZ1M2r5TP3f49Fpe0AfgwIiIj2',
    userName: 'Jeannette Soto',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'ORLANDOJOSE.CASTILLOMATUTE@pepsico.com',
    rolName: 'quality',
    userKey: 'HWrjQ2CXQHc21wiIABzFfojigaP2',
    userName: 'Orlando Castillo',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'CARLOSLUIS.CAVALCANTEPEREZ@pepsico.com',
    rolName: 'production',
    userKey: 'hR4nxDY9jfe2ScgU0To0wqkBq6y2',
    userName: 'Carlos Cavalcante',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'GONZALO.PEREZ1@pepsico.com',
    rolName: 'sass',
    userKey: '8AeaQ5m4RdWSojivuQO3m76o1YP2',
    userName: 'Gonzalo Perez',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'preyesventa@gmail.com',
    rolName: 'cerrillos',
    userKey: 'cmIaF4J2L6Z8LSbJsZawRRmeW9A3',
    userName: 'Pedro Reyes',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'daniel.caceres@pepsico.com',
    rolName: 'boxPickup',
    userKey: 'sp5icfQepdTOWmhHJkTRet738PU2',
    userName: 'Daniel Caceres',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'Leonardo.ArenasSoto@pepsico.com',
    rolName: 'technicalArea',
    userKey: 'Co0ryQjOYoSLYMNjSKlvziwvBFZ2',
    userName: 'Leonardo Arenas',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'valencia-susana@aramark.cl',
    rolName: 'cerrillos',
    userKey: 'oSQMDARqcEcKnJbzAPNlhmZKpTI2',
    userName: 'Susana Valencia',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'daniel.hernandez2@pepsico.com',
    rolName: 'logistics',
    userKey: 'Gj8GNUix78ejGA7xmv8HIj16W6F3',
    userName: 'Daniel Hernandez',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'EDUARD.ARAUJOSANDIA@pepsico.com',
    rolName: 'technicalArea',
    userKey: 'jd9fBJFoB1XPCrKctDP2USzxsCL2',
    userName: 'Eduardo Araujo',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'cristian.kaiser@pepsico.com',
    rolName: 'production',
    userKey: 'cIUP6CcbqEemDXVbNbUV4FFf3YE3',
    userName: 'Cristian Kaiser',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'alvaro.mora@pepsico.com',
    rolName: 'technicalArea',
    userKey: 'G87yBlMYL7U4gggOwSVrB7cdW5Q2',
    userName: 'Alvaro Mora',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'NERIORAMON.GONZALEZPETIT@pepsico.com',
    rolName: 'logistics',
    userKey: 'KgpOQZVrhxdtTBxCcDsSkHD8Yfx1',
    userName: 'Nerio Gonzalez',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'sebastian.alvarado1@pepsico.com',
    rolName: 'technicalArea',
    userKey: 'CkuJflB5Z8gCfotC5aBxiu9dzR03',
    userName: 'Sebastian Alvarado',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'Juan.VallejosAguilera@pepsico.com',
    rolName: 'production',
    userKey: '0uCKRoLg0VRMqdkisLa28YewpYA2',
    userName: 'Juan Vallejos',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'Jose.Inostroza@pepsico.com',
    rolName: 'technicalArea',
    userKey: 'RgTD593CXLcPuM2xo1nRJQW9EpF3',
    userName: 'Jose Inostroza',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'cristianalejandro.fernandez@pepsico.com',
    rolName: 'production',
    userKey: 'nDl5E3zTAeavWPwmNaZr4Bbx8bX2',
    userName: 'Cristian Fernandez',
  },
  {
    company: 'swer17wA10eNmlIhbQUv',
    email: 'mario.tobar@pepsico.com',
    rolName: 'administration',
    userKey: 'ZIGJYY28oiMNOECVbuWkSPfrINn1',
    userName: 'Mario Tobar',
  },
]

export default allUsers