import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import logo from '../../assets/LogoHseqtechcl.png'
import AuthLayout from '../layouts/AuthLayout'
import { auth, sendPasswordResetEmail, FirebaseError } from '../../config/firebaseConfig'
import validationSchema from './validationSchema'
import ColorButton from '../../common/ColorButton'

const Brand = () => (
  <img
    style={{
      width: '300px',
      height: '105px',
      display: 'block',
      margin: '0 auto 0 auto',
      backgroundColor: 'white',
    }}
    src={logo}
    alt="Pepsico"
  />
)
const ResetPassword = () => {
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: (values) => {
      console.log(
        'Ha iniciado el proceso de reseteo de contraseña',
        JSON.stringify(values, null, 2),
      )
    },
  })

  return (
    // eslint-disable-next-line
    <>
      <AuthLayout>
        <Paper
          sx={{ padding: (theme) => theme.spacing(6) }}
        >
          <Brand />
          <Typography
            component="h2"
            variant="body1"
            align="center"
          >
            Por favor ingrese su e-mail para resetear la contraseña
          </Typography>
          <div>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                sx={{ my: 2 }}
                fullWidth
                id="email"
                name="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <ColorButton
                onClick={() => {
                  console.log(formik.values.email && 'Email : ', formik.values.email)
                  if (!formik.values.email) {
                    toast.error('Por favor ingrese un email')
                    return
                  }
                  sendPasswordResetEmail(auth, formik.values.email)
                    .then(() => {
                      toast.success('Se ha enviado un email para resetear la contraseña')
                    })
                    .catch((error) => {
                      let message: string
                      if (error instanceof Error) {
                        message = error.message
                      } else if (error instanceof FirebaseError) {
                        message = error.code
                      } else {
                        message = JSON.stringify(error)
                      }

                      if (message.includes('auth/invalid-email')) {
                        toast.error('Por favor verifique el email ingresado')
                      } else if (
                        message.includes('auth/user-not-found')
                      ) {
                        toast.error('El email ingresado no se ha encontrado')
                      } else if (message.includes('auth/network-request-failed')) {
                        toast.error('Por favor verifique su conexión de internet')
                      } else {
                        toast.error(message)
                      }
                      console.error('Visualizando el error ', error)
                    })
                }}
                fullWidth
              >
                Resetear contraseña
              </ColorButton>
            </form>

          </div>
          <Link
            to="/"
            style={{ color: 'white', textDecoration: 'none' }}
          >
            <ColorButton
              sx={{ mt: 4 }}
              fullWidth
            >
              Volver al inicio
            </ColorButton>
          </Link>
        </Paper>
      </AuthLayout>
    </>
  )
}

export default ResetPassword