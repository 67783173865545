import * as React from 'react'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import DashboardIcon from '@mui/icons-material/Dashboard'
import PeopleIcon from '@mui/icons-material/People'
import AssignmentIcon from '@mui/icons-material/Assignment'
import LiveHelpIcon from '@mui/icons-material/LiveHelp'
import LogoutIcon from '@mui/icons-material/Logout'
import { Link, useHistory } from 'react-router-dom'
import { signOut } from 'firebase/auth'
import { auth } from '../../config/firebaseConfig'

export const mainListItems = (
  <div>
    <Link to="/start" style={{ textDecoration: 'none' }}>
      <ListItem button>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Inicio" />
      </ListItem>
    </Link>
    <Link to="/employeelist" style={{ textDecoration: 'none' }}>
      <ListItem button>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Personal" />
      </ListItem>
    </Link>
    <Link to="/support" style={{ textDecoration: 'none' }}>
      <ListItem button>
        <ListItemIcon>
          <LiveHelpIcon />
        </ListItemIcon>
        <ListItemText primary="Soporte" />
      </ListItem>
    </Link>
  </div>
)

export const secondaryListItems = (
  <div>
    <Link to="/all" style={{ textDecoration: 'none' }}>
      <ListItem button>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Todos los Permisos de Trabajo" />
      </ListItem>
    </Link>
  </div>
)

export const TerciaryListItems = () => {
  const history = useHistory()
  const signout = () => {
    signOut(auth).then(() => {
      history.push('/')
    }).catch((error) => {
      console.log('hubo un error : ', error)
    })
  }

  return (
    <div>
      <ListItem
        button
        onClick={signout}
      >
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary="Cerrar sesión" />
      </ListItem>
    </div>
  )
}