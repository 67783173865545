import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Container from '@mui/material/Container'
import DashboardLayout from '../layouts/DashboardLayout'
import allUsers from '../../common/allUsers'

const EmployeeList = () => {
  const sortedByNameEmployeeList = allUsers
    .sort((a, b) => (a.userName.toLowerCase() > b.userName.toLowerCase() ? 1 : -1))

  return (
    <>
      <DashboardLayout>
        <Container
          maxWidth="lg"
          sx={{ mt: 4, mb: 4 }}
        >
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Nombre</TableCell>
                  <TableCell align="left">Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedByNameEmployeeList.map((row) => (
                  <TableRow
                    key={row.userKey}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="left">{row.userName}</TableCell>
                    <TableCell align="left">{row.email.toLowerCase()}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </DashboardLayout>
    </>
  )
}

export default EmployeeList