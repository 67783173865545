import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { DataGrid } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'
import DashboardLayout from '../layouts/DashboardLayout'
import {
  collection, getDocs, orderBy, query, db,
} from '../../config/firebaseConfig'
import useAuth from '../../hooks/useAuth'
import FullPageLoader from '../../common/FullPageLoader'

const AllThePermits = () => {
  const { user } = useAuth()
  const [rows, setRows] = useState()
  const [dataIsLoading, setDataIsLoading] = useState(true)

  const allPermits = []

  const getAllDocuments = async () => {
    const formsRef = collection(db, 'forms')

    try {
      const q = query(formsRef, orderBy('newFormCreatedAt', 'desc'))
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        const { id } = doc
        allPermits.push({ id, ...doc.data() })
      })
      setDataIsLoading(false)
    } catch (error) {
      console.log('Error : ', error)
    }
    setRows(allPermits)
  }

  useEffect(() => {
    getAllDocuments()
  }, [])

  const columns = [
    {
      field: 'id',
      headerName: 'Identificador del permiso de trabajo',
      minWidth: 270,
      renderCell: (params) => (
        <>
          <Link
            to={{
              pathname: `/all/${params.value}`,
              state: {
                rows,
              },
            }}
            style={{ textDecoration: 'none' }}
          >
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: '#fbc02d',
                marginLeft: 5,
              }}
            >
              {`Ver ${params.value}`}
            </Button>
          </Link>
        </>
      ),
    },
    {
      field: 'peopleApprovedAllPages',
      headerName: 'Aprobado por asignados',
      width: 200,
      renderCell: ({ value }) => (
        <Typography sx={{
          ...(value === true && {
            color: 'green',
          }),
          ...(value === false && {
            color: 'red',
          }),
        }}
        >
          {value === true ? 'Aprobado' : 'No aprobado'}
        </Typography>
      ),
    },
    {
      field: 'p2date',
      headerName: 'Fecha de creación',
      width: 200,
      renderCell: ({ value }) => (
        <Typography sx={{
          ...(value === true && {
            color: 'green',
          }),
          ...(value === false && {
            color: 'red',
          }),
        }}
        >
          {value || 'No finalizó rellenado'}
        </Typography>
      ),
    },
    {
      field: 'newFormPersonEmailWhoDid',
      headerName: 'Quién creó',
      flex: 1,
      width: 200,
    },
    {
      field: 'paramedicApprovedAllWorkers',
      headerName: 'Aprobado por paramédico',
      flex: 1,
      width: 200,
      renderCell: ({ value }) => (
        <Typography sx={{
          ...(value === true && {
            color: 'green',
          }),
          ...(value === false && {
            color: 'red',
          }),
        }}
        >
          {value === true ? 'Aprobado' : 'No aprobado'}
        </Typography>
      ),
    },
  ]

  return (
    <>
      <DashboardLayout>
        {dataIsLoading || !user ? <FullPageLoader /> : (
          <>
            <Container
              maxWidth="lg"
              sx={{ mt: 4, mb: 4 }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={8} lg={12}>
                  <Typography sx={{ mb: 2 }}>
                    Todos los permisos de trabajo
                  </Typography>
                  <div style={{
                    width: '100%',
                  }}
                  >
                    <div style={{ display: 'flex', height: '100%' }}>
                      <div style={{ flexGrow: 1 }}>
                        <DataGrid
                          autoHeight
                          disableSelectionOnClick
                          disableColumnSelector
                          componentsProps={{
                            pagination: {
                              labelDisplayedRows: ({ from, to, count }) => `${from}-${to} de ${count}`,
                              nextIconButtonProps: {
                                disabled: false,
                              },
                            },
                          }}
                          localeText={{
                            noRowsLabel: 'No hay permisos de trabajo',
                            noResultsOverlayLabel: 'No se encontraron resultados.',
                            errorOverlayDefaultLabel: 'Ocurrió un error.',
                            toolbarColumns: 'Columnas',
                            toolbarColumnsLabel: 'Seleccionar columnas',
                            toolbarFilters: 'Filtros',
                            toolbarFiltersLabel: 'Mostrar filtros',
                            toolbarFiltersTooltipHide: 'Esconder filtros',
                            toolbarFiltersTooltipShow: 'Mostrar filtros',
                            toolbarFiltersTooltipActive: (count) => (count !== 1 ? `${count} filtros activos` : `${count} filtro activo`),
                            columnsPanelTextFieldLabel: 'Encontrar columna',
                            columnsPanelTextFieldPlaceholder: 'Título de la columna',
                            columnsPanelDragIconLabel: 'Reordenar columna',
                            columnsPanelShowAllButton: 'Mostrar todos',
                            columnsPanelHideAllButton: 'Esconder todos',
                            filterPanelAddFilter: 'Agregar filtro',
                            filterPanelDeleteIconLabel: 'Borrar',
                            filterPanelOperators: 'Operadores',
                            filterPanelOperatorAnd: 'Y',
                            filterPanelOperatorOr: 'O',
                            filterPanelColumns: 'Columnas',
                            filterPanelInputLabel: 'Valor',
                            filterPanelInputPlaceholder: 'Filtrar valor',
                            filterOperatorContains: 'contiene',
                            filterOperatorEquals: 'es igual a ',
                            filterOperatorStartsWith: 'empieza con',
                            filterOperatorEndsWith: 'termina con',
                            filterOperatorIs: 'es',
                            filterOperatorNot: 'no es',
                            filterOperatorAfter: 'está después de',
                            filterOperatorOnOrAfter: 'está en o después de',
                            filterOperatorBefore: 'está antes de',
                            filterOperatorOnOrBefore: 'está en o antes de',
                            filterOperatorIsEmpty: 'está vacío',
                            filterOperatorIsNotEmpty: 'no está vacío',
                            filterValueAny: 'cualquiera',
                            filterValueTrue: 'verdadero',
                            filterValueFalse: 'falso',
                            columnMenuLabel: 'Menu',
                            columnMenuShowColumns: 'Mostrar columnas',
                            columnMenuFilter: 'Filtrar',
                            columnMenuHideColumn: 'Esconder columna',
                            columnMenuUnsort: 'Quitar orden',
                            columnMenuSortAsc: 'Ordenar por ascendente',
                            columnMenuSortDesc: 'Ordenar por descendente',
                            footerRowSelected: (count) => (count !== 1
                              ? `${count.toLocaleString()} filas seleccionadas`
                              : `${count.toLocaleString()} fila seleccionada`),
                            footerTotalRows: 'Total de filas:',
                            footerTotalVisibleRows: (visibleCount, totalCount) => `${visibleCount} de ${totalCount}`,
                            labelDisplayedRows: ({ from, to, count: totalCount }) => `Showing ${from} to ${to} of ${totalCount}`,
                            MuiTablePagination: {
                              labelRowsPerPage: 'Mostrar',
                            },
                          }}
                          columns={columns}
                          rows={rows}
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </>
        )}
      </DashboardLayout>

    </>
  )
}

export default AllThePermits