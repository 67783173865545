import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom'
import ResetPassword from '../components/auth/ResetPassword'
import Signin from '../components/auth/SigninScreen'
import AllThePermits from '../components/dashboard/AllThePermits'
import AllThePermitsView1 from '../components/dashboard/AllThePermitsView1'
import EmployeeList from '../components/dashboard/EmployeeList'
import StartPage from '../components/dashboard/StartPage'
import Support from '../components/dashboard/Support'

const Routes = () => (
  <>
    <Router>
      <Switch>
        <Route path="/support">
          <Support />
        </Route>
        <Route path="/all/:id">
          <AllThePermitsView1 />
        </Route>
        <Route path="/all">
          <AllThePermits />
        </Route>
        <Route path="/employeelist">
          <EmployeeList />
        </Route>
        <Route path="/resetpassword">
          <ResetPassword />
        </Route>
        <Route path="/start">
          <StartPage />
        </Route>
        <Route path="/">
          <Signin />
        </Route>
      </Switch>
    </Router>
  </>
)

export default Routes