import {
  db,
  doc,
  getDoc,
} from '../config/firebaseConfig'
import { Form } from '../types'

const getFirestoreDocById = async (formId: string): Promise<Form> => {
  const formDocSnap = await getDoc(doc(db, `forms/${formId}`))
  if (!formDocSnap.exists()) throw new Error('Document does not exist')
  return formDocSnap.data() as Form
}

export default getFirestoreDocById