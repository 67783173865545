import { initializeApp, FirebaseError } from 'firebase/app'
import {
  getAuth, sendPasswordResetEmail, signOut,
} from 'firebase/auth'
import {
  getFirestore, collection, getDocs, getDoc, setDoc, addDoc, updateDoc, where, query, orderBy,
  doc, Timestamp,
} from 'firebase/firestore'
import {
  getStorage, ref, uploadBytesResumable, getDownloadURL,
} from 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyBdUufOYMxbfI7LwHYQWpismtsfxWHy4e8',
  authDomain: 'pepsico-pt-hseqtech.firebaseapp.com',
  projectId: 'pepsico-pt-hseqtech',
  storageBucket: 'pepsico-pt-hseqtech.appspot.com',
  messagingSenderId: '977132534139',
  appId: '1:977132534139:web:1214325660f4d35e0da5de',
}

const app = initializeApp(firebaseConfig)
const auth = getAuth()
const db = getFirestore()
const storage = getStorage()

export {
  app,
  auth,
  db,
  storage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  doc,
  Timestamp,
  where,
  query,
  orderBy,
  collection,
  getDocs,
  setDoc,
  getDoc,
  addDoc,
  updateDoc,
  signOut,
  FirebaseError,
  sendPasswordResetEmail,
}