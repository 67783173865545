import { createTheme } from '@mui/material/styles'
import { red } from '@mui/material/colors'

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1440,
  },
}

const theme = createTheme({
  typography: {
    fontFamily: [
      'Roboto',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#fbc02d',
    },
    secondary: {
      main: '#4782da',
    },
    error: {
      main: red.A400,
    },
  },
  breakpoints,
})

export default theme