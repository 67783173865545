import { Typography } from '@mui/material'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import GradingIcon from '@mui/icons-material/Grading'
import CheckIcon from '@mui/icons-material/Check'
import PauseIcon from '@mui/icons-material/Pause'
import HeightIcon from '@mui/icons-material/Height'
import CompressIcon from '@mui/icons-material/Compress'
import CleaningServicesIcon from '@mui/icons-material/CleaningServices'
import ConstructionIcon from '@mui/icons-material/Construction'
import BuildIcon from '@mui/icons-material/Build'
import ScienceIcon from '@mui/icons-material/Science'
import StarIcon from '@mui/icons-material/Star'
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment'
import BoltIcon from '@mui/icons-material/Bolt'

export const FilledOut = ({ filledOutDocs }: any) => (
  <Paper
    sx={{
      p: 3,
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Box display="flex" alignItems="center">
      <GradingIcon sx={{ display: 'flex', color: '#4E8098' }} />
      <Typography sx={{ display: 'flex', ml: 2 }} variant="h6">
        {`Rellenados : ${filledOutDocs && filledOutDocs.length}`}
      </Typography>
    </Box>
  </Paper>
)

export const Approved = ({ approvedDocs }: any) => (
  <Paper
    sx={{
      p: 3,
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Box display="flex" alignItems="center">
      <CheckIcon sx={{ display: 'flex', color: '#04A777' }} />
      <Typography sx={{ display: 'flex', ml: 2 }} variant="h6">
        {`Aprobados : ${approvedDocs && approvedDocs.length}`}
      </Typography>
    </Box>
  </Paper>
)

export const InProgress = ({ inProgressDocs }: any) => (
  <Paper
    sx={{
      p: 3,
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Box display="flex" alignItems="center">
      <PauseIcon sx={{ display: 'flex', color: '#C33C54' }} />
      <Typography sx={{ display: 'flex', ml: 2 }} variant="h6">
        {`En proceso : ${inProgressDocs && inProgressDocs.length}`}
      </Typography>
    </Box>
  </Paper>
)

export const P6Docs = ({ p6Docs }: any) => (
  <Paper
    sx={{
      p: 3,
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Box display="flex" alignItems="center">
      <HeightIcon sx={{ display: 'flex', color: '#939196' }} />
      <Typography sx={{ display: 'flex', ml: 2 }} variant="h6">
        {`Tienen trabajo en altura : ${p6Docs && p6Docs.length}`}
      </Typography>
    </Box>
  </Paper>
)

export const P7Docs = ({ p7Docs }: any) => (
  <Paper
    sx={{
      p: 3,
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Box display="flex" alignItems="center">
      <CompressIcon sx={{ display: 'flex', color: '#F79D5C' }} />
      <Typography sx={{ display: 'flex', ml: 2 }} variant="h6">
        {`Tienen espacios confinados : ${p7Docs && p7Docs.length}`}
      </Typography>
    </Box>
  </Paper>
)

export const P8Docs = ({ p8Docs }: any) => (
  <Paper
    sx={{
      p: 3,
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Box display="flex" alignItems="center">
      <CleaningServicesIcon sx={{ display: 'flex', color: '#01BAEF' }} />
      <Typography sx={{ display: 'flex', ml: 2 }} variant="h6">
        {`Tienen aseos : ${p8Docs && p8Docs.length}`}
      </Typography>
    </Box>
  </Paper>
)

export const P9Docs = ({ p9Docs }: any) => (
  <Paper
    sx={{
      p: 3,
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Box display="flex" alignItems="center">
      <ConstructionIcon sx={{ display: 'flex', color: '#466995' }} />
      <Typography sx={{ display: 'flex', ml: 2 }} variant="h6">
        {`Tienen izaje : ${p9Docs && p9Docs.length}`}
      </Typography>
    </Box>
  </Paper>
)
export const P10Docs = ({ p10Docs }: any) => (
  <Paper
    sx={{
      p: 3,
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Box display="flex" alignItems="center">
      <ScienceIcon sx={{ display: 'flex', color: '#8B8982' }} />
      <Typography sx={{ display: 'flex', ml: 2 }} variant="h6">
        {`Tienen uso de químicos : ${p10Docs && p10Docs.length}`}
      </Typography>
    </Box>
  </Paper>
)
export const P11Docs = ({ p11Docs }: any) => (
  <Paper
    sx={{
      p: 3,
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Box display="flex" alignItems="center">
      <BuildIcon sx={{ display: 'flex', color: '#84A59D' }} />
      <Typography sx={{ display: 'flex', ml: 2 }} variant="h6">
        {`Tienen mantenimiento y ssgg : ${p11Docs && p11Docs.length}`}
      </Typography>
    </Box>
  </Paper>
)
export const P12Docs = ({ p12Docs }: any) => (
  <Paper
    sx={{
      p: 3,
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Box display="flex" alignItems="center">
      <StarIcon sx={{ display: 'flex', color: '#40798C' }} />
      <Typography sx={{ display: 'flex', ml: 2 }} variant="h6">
        {`Tienen buenas prácticas de manufactura : ${p12Docs && p12Docs.length}`}
      </Typography>
    </Box>
  </Paper>
)
export const P13Docs = ({ p13Docs }: any) => (
  <Paper
    sx={{
      p: 3,
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Box display="flex" alignItems="center">
      <LocalFireDepartmentIcon sx={{ display: 'flex', color: '#D5AC4E' }} />
      <Typography sx={{ display: 'flex', ml: 2 }} variant="h6">
        {`Tienen trabajo en caliente : ${p13Docs && p13Docs.length}`}
      </Typography>
    </Box>
  </Paper>
)
export const P15Docs = ({ p15Docs }: any) => (
  <Paper
    sx={{
      p: 3,
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Box display="flex" alignItems="center">
      <BoltIcon sx={{ display: 'flex', color: '#788AA3' }} />
      <Typography sx={{ display: 'flex', ml: 2 }} variant="h6">
        {`Tienen bloqueo de energías : ${p15Docs && p15Docs.length}`}
      </Typography>
    </Box>
  </Paper>
)