import { createContext } from 'react'
import { User, signOut } from 'firebase/auth'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Children } from '../types'
import { auth } from '../config/firebaseConfig'
import FullPageLoader from '../common/FullPageLoader'

const AuthContext = createContext({
  user: null as User | null | undefined,
  signOut,
})

const AuthProvider = ({ children }: Children) => {
  const [user, loading] = useAuthState(auth)

  return (
    <AuthContext.Provider value={{ user, signOut }}>
      {!loading ? children : <FullPageLoader />}
    </AuthContext.Provider>
  )
}

export { AuthProvider, AuthContext }