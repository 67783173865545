import { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import Slide from '@mui/material/Slide'
import { useParams } from 'react-router-dom'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Checkbox from '@mui/material/Checkbox'
import getFirestoreDocById from '../../common/getFirestoreDocById'
import { Form, UrlParams } from '../../types'
import DashboardLayout from '../layouts/DashboardLayout'
import YellowSwitch from '../../common/YellowSwitch'
import monthNames from '../../common/monthNames'
import FullPageLoader from '../../common/FullPageLoader'

const ReviseStep1 = () => {
  const notAvailableData = 'No disponible'

  const { id } = useParams<UrlParams>()

  const [, setDocIsLoading] = useState(true)
  const [formData, setFormData] = useState<Form>()

  useEffect(() => {
    (async () => {
      if (id) {
        const res = await getFirestoreDocById(id)
        if (res) {
          setFormData(res)
          setDocIsLoading(false)
        } else {
          setDocIsLoading(false)
        }
      }
    })()
  }, [])

  return (
    <DashboardLayout>
      {formData ? (
        <>
          <Slide
            direction="right"
            in
            mountOnEnter
            unmountOnExit
          >
            <Grid sx={{ mt: 4, mb: 8 }} container justifyContent="center">
              <Grid item xs={10} sm={9} lg={7}>
                <Card sx={{ p: 2, mb: 10 }}>
                  <CardContent>
                    <Typography
                      variant="h6"
                      color="text.secondary"
                      gutterBottom
                    >
                      REVISIÓN DE PERMISO DE TRABAJO INTEGRAL
                    </Typography>
                    <Typography
                      variant="body1"
                      gutterBottom
                    >
                      El permiso
                      de trabajo representa
                      una guía para la identificación
                      de peligros y riesgos del
                      trabajo a ejecutar dentro
                      del área concesionada. Esta
                      guía no reemplaza las
                      obligaciones de la
                      normativa legal vigente
                      establecidas para los temas
                      relacionados en
                      Seguridad, Salud Ocupacional.
                    </Typography>
                  </CardContent>
                </Card>

                <Typography
                  sx={{ mb: 2 }}
                  align="center"
                  variant="h6"
                  color="text.secondary"
                  gutterBottom
                >
                  {`ANTECEDENTES ${!formData?.p2companyName ? '(No disponible)' : ''}`}
                </Typography>

                {formData?.p2companyName ? (
                  <>
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      label="Nombre de la Empresa"
                      variant="outlined"
                      value={formData?.p2companyName ?? notAvailableData}
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      label="Nombre Supervisor Contratista"
                      variant="outlined"
                      value={formData?.p2contratSupervisorName ?? notAvailableData}
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      label="Teléfono Supervisor Contratista"
                      variant="outlined"
                      value={formData?.p2contratSupervisorPhone ?? notAvailableData}
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      label="Nombre ERP"
                      variant="outlined"
                      value={formData?.p2erpName ?? notAvailableData}
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      label="Teléfono ERP"
                      variant="outlined"
                      value={formData?.p2erpPhone ?? notAvailableData}
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      label="Rut"
                      variant="outlined"
                      value={formData?.p2rut ?? notAvailableData}
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      label="Mutualidad"
                      variant="outlined"
                      value={formData?.p2mutualidad ?? notAvailableData}
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      label="Área que solicita el trabajo"
                      variant="outlined"
                      value={formData?.p2requestingArea
                        ?? notAvailableData}
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      label="Coordinador de trabajo Pepsico"
                      variant="outlined"
                      value={formData?.p2pepWorkCoordinator
                        ?? notAvailableData}
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      label="Área de trabajo específica"
                      variant="outlined"
                      value={formData?.p2specificWorkArea
                        ?? notAvailableData}
                      InputLabelProps={{ shrink: true }}
                    />

                    {(formData?.p2specificWorkAreaImage?.length > 1 && formData?.p2specificWorkAreaImage !== 'none')
                      && (
                        <Card sx={{ maxWidth: 345, mt: 2, mb: 4 }}>
                          <CardMedia
                            component="img"
                            height="140"
                            image={
                              formData?.p2specificWorkAreaImage
                              && formData?.p2specificWorkAreaImage
                            }
                            alt="area de trabajo especifica"
                          />
                          <CardContent>
                            <Typography gutterBottom variant="subtitle1" component="div">
                              Área de trabajo específica
                            </Typography>
                          </CardContent>
                        </Card>
                      )}

                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      label="Fecha"
                      variant="outlined"
                      value={(formData?.p2date && formData?.p2date) ?? 'No disponible'}
                      InputLabelProps={{ shrink: true }}
                    />
                    {formData?.p2startTime ? (
                      <TextField
                        sx={{ mb: 2 }}
                        fullWidth
                        label="Hora inicial"
                        variant="outlined"
                        value={`${formData?.p2startTime?.toDate().getHours()}:${formData?.p2startTime && formData?.p2startTime?.toDate().getMinutes() < 10 ? '0' : ''}${formData?.p2startTime && formData?.p2startTime?.toDate().getMinutes()}`}
                        InputLabelProps={{ shrink: true }}
                      />
                    ) : null}

                    {formData?.p2finishTime ? (
                      <TextField
                        sx={{ mb: 2 }}
                        fullWidth
                        label="Hora final"
                        variant="outlined"
                        value={`${formData?.p2finishTime.toDate().getHours()}:${formData?.p2finishTime && formData?.p2finishTime.toDate().getMinutes() < 10 ? '0' : ''}${formData?.p2finishTime && formData?.p2finishTime.toDate().getMinutes()}`}
                        InputLabelProps={{ shrink: true }}
                      />
                    ) : null}

                    <TextField
                      disabled
                      sx={{ mb: 4 }}
                      fullWidth
                      label="Extension"
                      variant="outlined"
                      value={formData?.p2extensionShow ? formData?.p2extensionShow : 'La extensión no aplica en esta página'}
                      InputLabelProps={{ shrink: true }}
                    />
                  </>
                ) : null}

                <Typography
                  sx={{ mb: 2, mt: 2 }}
                  align="center"
                  variant="h6"
                  color="text.secondary"
                  gutterBottom
                >
                  {`ANÁLISIS SEGURO DE TRABAJO ${!formData?.p3description1 ? '(No disponible)' : ''}`}
                </Typography>
                <TextField
                  sx={{ mb: 2 }}
                  fullWidth
                  label="1 - Descripción del trabajo (paso a paso)"
                  variant="outlined"
                  value={formData?.p3description1}
                  InputLabelProps={{ shrink: true }}
                  style={
                    formData?.p3description1
                      ? { display: 'flex' }
                      : { display: 'none' }
                  }
                />

                <TextField
                  sx={{ mb: 2 }}
                  fullWidth
                  label="1 - Medidas de control para cada paso"
                  variant="outlined"
                  value={formData?.p3controlMeasure1}
                  InputLabelProps={{ shrink: true }}
                  style={
                    formData?.p3controlMeasure1
                      ? { display: 'flex' }
                      : { display: 'none' }
                  }
                />

                <TextField
                  sx={{ mb: 2 }}
                  style={
                    formData?.p3description2
                      ? { display: 'flex' }
                      : { display: 'none' }
                  }
                  fullWidth
                  label="2 - Descripción del trabajo (paso a paso)"
                  variant="outlined"
                  value={formData?.p3description2}
                  InputLabelProps={{ shrink: true }}
                />

                <TextField
                  sx={{ mb: 2 }}
                  style={
                    formData?.p3controlMeasure2
                      ? { display: 'flex' }
                      : { display: 'none' }
                  }
                  fullWidth
                  label="2 - Medidas de control para cada paso"
                  variant="outlined"
                  value={formData?.p3controlMeasure2}
                  InputLabelProps={{ shrink: true }}
                />

                <TextField
                  sx={{ mb: 2 }}
                  style={
                    formData?.p3description3
                      ? { display: 'flex' }
                      : { display: 'none' }
                  }
                  fullWidth
                  label="3 - Descripción del trabajo (paso a paso)"
                  variant="outlined"
                  value={formData?.p3description3}
                  InputLabelProps={{ shrink: true }}
                />

                <TextField
                  sx={{ mb: 2 }}
                  style={
                    formData?.p3controlMeasure3
                      ? { display: 'flex' }
                      : { display: 'none' }
                  }
                  fullWidth
                  label="3 - Medidas de control para cada paso"
                  variant="outlined"
                  value={formData?.p3controlMeasure3}
                  InputLabelProps={{ shrink: true }}
                />

                <TextField
                  sx={{ mb: 2 }}
                  style={
                    formData?.p3description4
                      ? { display: 'flex' }
                      : { display: 'none' }
                  }
                  fullWidth
                  label="4 - Descripción del trabajo (paso a paso)"
                  variant="outlined"
                  value={formData?.p3description4}
                  InputLabelProps={{ shrink: true }}
                />

                <TextField
                  sx={{ mb: 2 }}
                  style={
                    formData?.p3controlMeasure4
                      ? { display: 'flex' }
                      : { display: 'none' }
                  }
                  fullWidth
                  label="4 - Medidas de control para cada paso"
                  variant="outlined"
                  value={formData?.p3controlMeasure4}
                  InputLabelProps={{ shrink: true }}
                />

                <TextField
                  sx={{ mb: 2 }}
                  style={
                    formData?.p3description5
                      ? { display: 'flex' }
                      : { display: 'none' }
                  }
                  fullWidth
                  label="5 - Descripción del trabajo (paso a paso)"
                  variant="outlined"
                  value={formData?.p3description5}
                  InputLabelProps={{ shrink: true }}
                />

                <TextField
                  sx={{ mb: 2 }}
                  style={
                    formData?.p3controlMeasure5
                      ? { display: 'flex' }
                      : { display: 'none' }
                  }
                  fullWidth
                  label="5 - Medidas de control para cada paso"
                  variant="outlined"
                  value={formData?.p3controlMeasure5}
                  InputLabelProps={{ shrink: true }}
                />

                <TextField
                  sx={{ mb: 2 }}
                  style={
                    formData?.p3description6
                      ? { display: 'flex' }
                      : { display: 'none' }
                  }
                  fullWidth
                  label="6 - Descripción del trabajo (paso a paso)"
                  variant="outlined"
                  value={formData?.p3description6}
                  InputLabelProps={{ shrink: true }}
                />

                <TextField
                  sx={{ mb: 2 }}
                  style={
                    formData?.p3controlMeasure6
                      ? { display: 'flex' }
                      : { display: 'none' }
                  }
                  fullWidth
                  label="6 - Medidas de control para cada paso"
                  variant="outlined"
                  value={formData?.p3controlMeasure6}
                  InputLabelProps={{ shrink: true }}
                />

                <TextField
                  sx={{ mb: 2 }}
                  style={
                    formData?.p3description7
                      ? { display: 'flex' }
                      : { display: 'none' }
                  }
                  fullWidth
                  label="7 - Descripción del trabajo (paso a paso)"
                  variant="outlined"
                  value={formData?.p3description7}
                  InputLabelProps={{ shrink: true }}
                />

                <TextField
                  sx={{ mb: 2 }}
                  style={
                    formData?.p3controlMeasure7
                      ? { display: 'flex' }
                      : { display: 'none' }
                  }
                  fullWidth
                  label="7 - Medidas de control para cada paso"
                  variant="outlined"
                  value={formData?.p3controlMeasure7}
                  InputLabelProps={{ shrink: true }}
                />

                <Typography
                  sx={{ mb: 2, mt: 2 }}
                  align="center"
                  variant="h6"
                  color="text.secondary"
                  gutterBottom
                >
                  {`LISTADO DE PERSONAL QUE EJECUTA EL TRABAJO ${!formData?.p4workerName1 ? '(No disponible)' : ''}`}
                </Typography>
                <TextField
                  sx={{ mb: 2 }}
                  fullWidth
                  label="Nombre del trabajador 1"
                  variant="outlined"
                  value={formData?.p4workerName1}
                  InputLabelProps={{ shrink: true }}
                  style={
                    formData?.p4workerName1
                      ? { display: 'flex' }
                      : { display: 'none' }
                  }
                />

                <TextField
                  style={
                    formData?.p4workerName2
                      ? { display: 'flex' }
                      : { display: 'none' }
                  }
                  sx={{ mb: 2 }}
                  fullWidth
                  label="Nombre del trabajador 2"
                  variant="outlined"
                  value={formData?.p4workerName2}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  style={
                    formData?.p4workerName3
                      ? { display: 'flex' }
                      : { display: 'none' }
                  }
                  sx={{ mb: 2 }}
                  fullWidth
                  label="Nombre del trabajador 3"
                  variant="outlined"
                  value={formData?.p4workerName3}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  style={
                    formData?.p4workerName4
                      ? { display: 'flex' }
                      : { display: 'none' }
                  }
                  sx={{ mb: 2 }}
                  fullWidth
                  label="Nombre del trabajador 4"
                  variant="outlined"
                  value={formData?.p4workerName4}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  style={
                    formData?.p4workerName5
                      ? { display: 'flex' }
                      : { display: 'none' }
                  }
                  sx={{ mb: 2 }}
                  fullWidth
                  label="Nombre del trabajador 5"
                  variant="outlined"
                  value={formData?.p4workerName5}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  style={
                    formData?.p4workerName6
                      ? { display: 'flex' }
                      : { display: 'none' }
                  }
                  sx={{ mb: 2 }}
                  fullWidth
                  label="Nombre del trabajador 6"
                  variant="outlined"
                  value={formData?.p4workerName6}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  style={
                    formData?.p4workerName7
                      ? { display: 'flex' }
                      : { display: 'none' }
                  }
                  sx={{ mb: 2 }}
                  fullWidth
                  label="Nombre del trabajador 7"
                  variant="outlined"
                  value={formData?.p4workerName7}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  style={
                    formData?.p4workerName8
                      ? { display: 'flex' }
                      : { display: 'none' }
                  }
                  sx={{ mb: 2 }}
                  fullWidth
                  label="Nombre del trabajador 8"
                  variant="outlined"
                  value={formData?.p4workerName8}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  style={
                    formData?.p4workerName9
                      ? { display: 'flex' }
                      : { display: 'none' }
                  }
                  sx={{ mb: 2 }}
                  fullWidth
                  label="Nombre del trabajador 9"
                  variant="outlined"
                  value={formData?.p4workerName9}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  style={
                    formData?.p4workerName10
                      ? { display: 'flex' }
                      : { display: 'none' }
                  }
                  sx={{ mb: 2 }}
                  fullWidth
                  label="Nombre del trabajador 10"
                  variant="outlined"
                  value={formData?.p4workerName10}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  style={
                    formData?.p4workerName11
                      ? { display: 'flex' }
                      : { display: 'none' }
                  }
                  sx={{ mb: 10 }}
                  fullWidth
                  label="Nombre del trabajador 11"
                  variant="outlined"
                  value={formData?.p4workerName11}
                  InputLabelProps={{ shrink: true }}
                />
                <Typography
                  sx={{ mb: 2, mt: 2 }}
                  align="center"
                  variant="h6"
                  color="text.secondary"
                  gutterBottom
                >
                  {`RIESGOS ASOCIADOS ${!formData?.p5atrapam ? '(No disponible)' : ''}`}
                </Typography>

                {formData?.p5atrapam ? (
                  <>
                    <Box sx={{
                      display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                    }}
                    >
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Atrapamiento</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="atrapamiento"
                          name="atrapamiento"
                          value={formData?.p5atrapam}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5atrapam === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5atrapam === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5atrapam === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Aplastamiento</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="aplastamiento"
                          name="aplastamiento"
                          value={formData?.p5aplastam}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5aplastam === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5aplastam === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5aplastam === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Golpeado por</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="golpeadorpor"
                          name="golpeadorpor"
                          value={formData?.p5golpeadopor}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5golpeadopor === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5golpeadopor === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5golpeadopor === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Golpeado con</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="golpeadocon"
                          name="golpeadocon"
                          value={formData?.p5golpeadocon}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5golpeadocon === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5golpeadocon === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5golpeadocon === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Caída (mismo y distinto nivel)</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="caida"
                          name="caida"
                          value={formData?.p5caida}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5caida === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5caida === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5caida === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">
                          Contacto con objetos cortantes
                        </FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="contactoconobjetoscortantes"
                          name="contactoconobjetoscortantes"
                          value={formData?.p5contConObjCort}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5contConObjCort === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5contConObjCort === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5contConObjCort === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">
                          Contacto con objetos calientes
                        </FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="contactoconobjetoscalientes"
                          name="contactoconobjetoscalientes"
                          value={formData?.p5contConObjCal}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5contConObjCal === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5contConObjCal === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5contConObjCal === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">
                          Proyección de particulas
                        </FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="proyecciondeparticulas"
                          name="proyecciondeparticulas"
                          value={formData?.p5proyPartic}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5proyPartic === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5proyPartic === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5proyPartic === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">
                          Contacto con energía eléctrica
                        </FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="contactoconenergiaelectrica"
                          name="contactoconenergiaelectrica"
                          value={formData?.p5contactEnElectric}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5contactEnElectric === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5contactEnElectric === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5contactEnElectric === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Radiación UV</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="radiacionuv"
                          name="radiacionuv"
                          value={formData?.p5radiacionUv}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5radiacionUv === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5radiacionUv === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5radiacionUv === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Contacto con químicos</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="contactoconquimicos"
                          name="contactoconquimicos"
                          value={formData?.p5contQuimico}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5contQuimico === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5contQuimico === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5contQuimico === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Exposición a Polvo</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="exposicionapolvo"
                          name="exposicionapolvo"
                          value={formData?.p5exposicPolvo}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5exposicPolvo === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5exposicPolvo === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5exposicPolvo === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Exposición a Ruido</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="exposicionaruido"
                          name="exposicionaruido"
                          value={formData?.p5exposicRuido}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5exposicRuido === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5exposicRuido === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5exposicRuido === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Atropello</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="atropello"
                          name="atropello"
                          value={formData?.p5atropello}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5atropello === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5atropello === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5atropello === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Caída de materiales de altura</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="caidadematerialesdealtura"
                          name="caidadematerialesdealtura"
                          value={formData?.p5caidaMatAltura}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5caidaMatAltura === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5caidaMatAltura === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5caidaMatAltura === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Estrés Térmico</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="estrestermico"
                          name="estrestermico"
                          value={formData?.p5estresTerm}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5estresTerm === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5estresTerm === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5estresTerm === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Colisión / Choque</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="colisionchoque"
                          name="colisionchoque"
                          value={formData?.p5colisionChoque}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5colisionChoque === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5colisionChoque === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5colisionChoque === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Deficiencia de O2</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="deficienciade02"
                          name="deficienciade02"
                          value={formData?.p5deficienciao2}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5deficienciao2 === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5deficienciao2 === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5deficienciao2 === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Explosión</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="explosion"
                          name="explosion"
                          value={formData?.p5explosion}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5explosion === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5explosion === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5explosion === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Incendio</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="incendio"
                          name="incendio"
                          value={formData?.p5incendio}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5incendio === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5incendio === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5incendio === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Derrumbe / Desprendimiento</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="derrumbedesprendimiento"
                          name="derrumbedesprendimiento"
                          value={formData?.p5derrumbe}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5derrumbe === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5derrumbe === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5derrumbe === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Exposición a Gases</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="exposicionagases"
                          name="exposicionagases"
                          value={formData?.p5exposicGas}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5exposicGas === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5exposicGas === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5exposicGas === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Inmersión</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="inmersion"
                          name="inmersion"
                          value={formData?.p5immersion}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5immersion === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5immersion === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5immersion === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 10 }} component="fieldset">
                        <FormLabel component="legend">Fatiga de materiales</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="fatigademateriales"
                          name="fatigademateriales"
                          value={formData?.p5fatigMaterial}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p5fatigMaterial === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p5fatigMaterial === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p5fatigMaterial === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </>
                ) : null}

                <Typography
                  sx={{ mb: 2, mt: 2 }}
                  align="center"
                  variant="h6"
                  color="text.secondary"
                  gutterBottom
                >
                  TRABAJO EN ALTURA
                </Typography>
                <Grid display="flex" justifyContent="center" item xs={12}>
                  <FormControlLabel
                    control={(
                      <YellowSwitch
                        checked={formData?.p6Show}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    )}
                    label={formData?.p6Show ? 'Esta página aplica' : 'Esta página no aplica'}
                  />
                </Grid>
                <Box
                  component="div"
                  sx={{
                    display: formData?.p6Show ? 'block' : 'none',
                  }}
                >
                  <Grid display="flex" justifyContent="center" item xs={12}>
                    <TextField
                      sx={{ mb: 2, mt: 4, width: 300 }}
                      fullWidth
                      label="Llave de acceso número"
                      variant="outlined"
                      value={formData?.p6keyNumber ? formData?.p6keyNumber : 'No se ha ingresado llave de acceso'}
                    />
                  </Grid>

                  <Grid display="flex" justifyContent="center" item xs={12}>
                    <Box sx={{
                      display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                    }}
                    >
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Condiciones climáticas adversas (lluvia, neblina, etc)</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row', justifyContent: 'center' }}
                          aria-label="condicionesclimaticas"
                          name="condicionesclimaticas"
                          value={formData?.p6weatherConditions}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p6weatherConditions === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p6weatherConditions === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p6weatherConditions === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Box sx={{
                    display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                  }}
                  >
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">
                        Capacitación para trabajo en altura
                      </FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="capacitaciontrabajoenaltura"
                        name="capacitaciontrabajoenaltura"
                        value={formData?.p6trainingForHeightWork}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p6trainingForHeightWork === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p6trainingForHeightWork === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p6trainingForHeightWork === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box sx={{
                    display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                  }}
                  >
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">
                        Capacitación específica del trabajo
                      </FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="capacitacionespecifica"
                        name="capacitacionespecifica"
                        value={formData?.p6trainingEspecWork}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p6trainingEspecWork === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p6trainingEspecWork === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p6trainingEspecWork === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box sx={{
                    display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                  }}
                  >
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">
                        Radios de comunicación
                      </FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="radiosdecom"
                        name="radiosdecom"
                        value={formData?.p6radioCommunication}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p6radioCommunication === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p6radioCommunication === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p6radioCommunication === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box sx={{
                    display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                  }}
                  >
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">
                        Cinturón de Herramientas
                      </FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="cinturondeherramientas"
                        name="cinturondeherramientas"
                        value={formData?.p6toolBelt}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p6toolBelt === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p6toolBelt === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p6toolBelt === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box sx={{
                    display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                  }}
                  >
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">
                        Uso de puntos de anclaje
                        {' '}

                      </FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="usodepuntosdeanclaje"
                        name="usodepuntosdeanclaje"
                        value={formData?.p6useOfHookPoint}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p6useOfHookPoint === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p6useOfHookPoint === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p6useOfHookPoint === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box sx={{
                    display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                  }}
                  >
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">
                        Uso de escalas y/o plataformas
                      </FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="usodeescalasyoplat"
                        name="usodeescalasyoplat"
                        value={formData?.p6useofLadder}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p6useofLadder === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p6useofLadder === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p6useofLadder === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  {(formData?.p6useofLadderImage?.length > 1 && formData?.p6useofLadderImage !== 'none')
                    && (
                      <Card sx={{ maxWidth: 345, mt: 2, mb: 4 }}>
                        <CardMedia
                          component="img"
                          height="140"
                          image={
                            formData?.p6useofLadderImage
                            && formData?.p6useofLadderImage
                          }
                          alt="uso de escalas yo plataformas"
                        />
                        <CardContent>
                          <Typography gutterBottom variant="subtitle1" component="div">
                            Uso de escalas y/o plataformas
                          </Typography>
                        </CardContent>
                      </Card>
                    )}

                  <Box sx={{
                    display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                  }}
                  >
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">
                        Elevadores y Jaulas
                      </FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="elevadoresyjaulas"
                        name="elevadoresyjaulas"
                        value={formData?.p6elevator}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p6elevator === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p6elevator === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p6elevator === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  {(formData?.p6elevatorImage?.length > 1
                    && formData?.p6elevatorImage !== 'none')
                    && (
                      <Card sx={{ maxWidth: 345, mt: 2, mb: 4 }}>
                        <CardMedia
                          component="img"
                          height="140"
                          image={
                            formData?.p6elevatorImage
                            && formData?.p6elevatorImage
                          }
                          alt="elevadores y jaulas"
                        />
                        <CardContent>
                          <Typography gutterBottom variant="subtitle1" component="div">
                            Elevadores y Jaulas
                          </Typography>
                        </CardContent>
                      </Card>
                    )}

                  <Box sx={{
                    display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                  }}
                  >
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">Andamio Tipo euro (soporte y anclaje)</FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="andamiotipoeuro"
                        name="andamiotipoeuro"
                        value={formData?.p6andamTipeEuro}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p6andamTipeEuro === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p6andamTipeEuro === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p6andamTipeEuro === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  {(formData?.p6andamTipeEuroImage?.length > 1 && formData?.p6andamTipeEuroImage !== 'none')
                    && (
                      <Card sx={{ maxWidth: 345, mt: 2, mb: 4 }}>
                        <CardMedia
                          component="img"
                          height="140"
                          image={
                            formData?.p6andamTipeEuroImage
                            && formData?.p6andamTipeEuroImage
                          }
                          alt="andamio tipo euro (soporte y anclaje)"
                        />
                        <CardContent>
                          <Typography gutterBottom variant="subtitle1" component="div">
                            Andamio Tipo euro (soporte y anclaje)
                          </Typography>
                        </CardContent>
                      </Card>
                    )}

                  <Box sx={{
                    display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                  }}
                  >
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">Examen de Salud (Exámen de Altura)</FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="examendesaludeda"
                        name="examendesaludeda"
                        value={formData?.p6healthCheck}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p6healthCheck === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p6healthCheck === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p6healthCheck === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box sx={{
                    display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                  }}
                  >
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">Deslizador en línea vertical</FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="deslizadorenlinea"
                        name="deslizadorenlinea"
                        value={formData?.p6inlineSliderVertical}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p6inlineSliderVertical === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p6inlineSliderVertical === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p6inlineSliderVertical === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box sx={{
                    display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                  }}
                  >
                    <FormControl sx={{ display: 'flex', mb: 10 }} component="fieldset">
                      <FormLabel component="legend">Trabajo en altura bajo nivel</FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="trabajoenaltbn"
                        name="trabajoenaltbn"
                        value={formData?.p6heightJobLowLevel}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p6heightJobLowLevel === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p6heightJobLowLevel === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p6heightJobLowLevel === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Box>

                <Typography
                  sx={{ mb: 2, mt: 2 }}
                  align="center"
                  variant="h6"
                  color="text.secondary"
                  gutterBottom
                >
                  ESPACIOS CONFINADOS
                </Typography>

                <Grid display="flex" justifyContent="center" item xs={12}>
                  <FormControlLabel
                    control={(
                      <YellowSwitch
                        checked={formData?.p7Show}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    )}
                    label={formData?.p7Show ? 'Esta página aplica' : 'Esta página no aplica'}
                  />
                </Grid>
                <Box
                  component="div"
                  sx={{
                    display: formData?.p7Show ? 'block' : 'none',
                  }}
                >
                  <TextField
                    sx={{ mb: 2, mt: 4 }}
                    fullWidth
                    label="Llave de acceso número"
                    variant="outlined"
                    value={formData?.p7keyNumber}
                  />
                  <Typography>Medición 1:</Typography>
                  <Grid display="flex">
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      label="O2"
                      variant="outlined"
                      value={formData?.p7measure1o2}
                    />
                    <TextField
                      sx={{ mb: 2, ml: 1 }}
                      fullWidth
                      label="H2S"
                      variant="outlined"
                      value={formData?.p7measure1h2s}
                    />
                    <TextField
                      sx={{ mb: 2, ml: 1 }}
                      fullWidth
                      label="CO"
                      variant="outlined"
                      value={formData?.p7measure1co}
                    />
                  </Grid>
                  <Typography>Medición 2:</Typography>
                  <Grid display="flex">
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      label="O2"
                      variant="outlined"
                      value={formData?.p7measure2o2}
                    />
                    <TextField
                      sx={{ mb: 2, ml: 1 }}
                      fullWidth
                      label="H2S"
                      variant="outlined"
                      value={formData?.p7measure2h2s}
                    />
                    <TextField
                      sx={{ mb: 2, ml: 1 }}
                      fullWidth
                      label="CO"
                      variant="outlined"
                      value={formData?.p7measure2co}
                    />
                  </Grid>

                  <Grid display="flex" justifyContent="center">
                    <Typography>
                      Condiciones Aceptables Oxigeno 19,5 a 23,5%
                    </Typography>
                  </Grid>
                  <Grid display="flex" justifyContent="center">
                    <Typography>
                      CO al 0% , H2S al 0%
                    </Typography>
                  </Grid>
                  <Grid display="flex" justifyContent="center">
                    <Typography>
                      Temperatura máxima 35°C - LEL máximo 10%
                    </Typography>
                  </Grid>

                  <Box sx={{
                    display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                  }}
                  >
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">
                        Mantiene analizador de gases?
                      </FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="condicionesclimaticas"
                        name="condicionesclimaticas"
                        value={formData?.p7gasAn}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p7gasAn === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p7gasAn === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p7gasAn === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box sx={{
                    display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                  }}
                  >
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">
                        Dispositivos de ventilación forzada
                      </FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="condicionesclimaticas"
                        name="condicionesclimaticas"
                        value={formData?.p7deviceOfVentForced}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p7deviceOfVentForced === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p7deviceOfVentForced === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p7deviceOfVentForced === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box sx={{
                    display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                  }}
                  >
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">
                        Equipos de respiración autónoma
                      </FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="condicionesclimaticas"
                        name="condicionesclimaticas"
                        value={formData?.p7deviceOfAutonBreathing}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p7deviceOfAutonBreathing === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p7deviceOfAutonBreathing === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p7deviceOfAutonBreathing === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box sx={{
                    display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                  }}
                  >
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">
                        Radio de comunicación
                      </FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="condicionesclimaticas"
                        name="condicionesclimaticas"
                        value={formData?.p7comRadio}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p7comRadio === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p7comRadio === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p7comRadio === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box sx={{
                    display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                  }}
                  >
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">
                        Puesta tierra temporal en el lugar
                      </FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="condicionesclimaticas"
                        name="condicionesclimaticas"
                        value={formData?.p7landTempInPlace}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p7landTempInPlace === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p7landTempInPlace === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p7landTempInPlace === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box sx={{
                    display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                  }}
                  >
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">
                        Equipo de iluminación adicional
                      </FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="condicionesclimaticas"
                        name="condicionesclimaticas"
                        value={formData?.p7equipmentOfAdLight}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p7equipmentOfAdLight === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p7equipmentOfAdLight === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p7equipmentOfAdLight === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box sx={{
                    display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                  }}
                  >
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">
                        Requiere equipo de rescate
                      </FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="condicionesclimaticas"
                        name="condicionesclimaticas"
                        value={formData?.p7requireRescueDevice}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p7requireRescueDevice === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p7requireRescueDevice === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p7requireRescueDevice === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box sx={{
                    display: 'flex', justifyContent: 'center', mt: 4, mb: 10,
                  }}
                  >
                    <FormControl sx={{ display: 'flex', mb: 10 }} component="fieldset">
                      <FormLabel component="legend">
                        Condición climatica aceptable
                      </FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="condicionesclimaticas"
                        name="condicionesclimaticas"
                        value={formData?.p7acceptableWeatherCond}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p7acceptableWeatherCond === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p7acceptableWeatherCond === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p7acceptableWeatherCond === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Box>

                <Typography
                  sx={{ mb: 2, mt: 2 }}
                  align="center"
                  variant="h6"
                  color="text.secondary"
                  gutterBottom
                >
                  ASEOS
                </Typography>

                <Grid display="flex" justifyContent="center" item xs={12}>
                  <FormControlLabel
                    control={(
                      <YellowSwitch
                        checked={formData?.p8Show}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    )}
                    label={formData?.p8Show ? 'Esta página aplica' : 'Esta página no aplica'}
                  />
                </Grid>
                <Box
                  component="div"
                  sx={{
                    display: formData?.p8Show ? 'block' : 'none',
                  }}
                >
                  <Box sx={{
                    display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                  }}
                  >
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">Envases químicos rotulados</FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="atrapamiento"
                        name="atrapamiento"
                        value={formData?.p8chemEnvase}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p8chemEnvase === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p8chemEnvase === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p8chemEnvase === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">Cuenta con HDS</FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="aplastamiento"
                        name="aplastamiento"
                        value={formData?.p8hasHds}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p8hasHds === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p8hasHds === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p8hasHds === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>

                  </Box>

                  {(formData?.p8hasHdsImage?.length > 1
                    && formData?.p8hasHdsImage !== 'none')
                    && (
                      <Card sx={{ maxWidth: 345, mt: 2, mb: 4 }}>
                        <CardMedia
                          component="img"
                          height="140"
                          image={
                            formData?.p8hasHdsImage
                            && formData?.p8hasHdsImage
                          }
                          alt="cuenta con HDS"
                        />
                        <CardContent>
                          <Typography gutterBottom variant="subtitle1" component="div">
                            Cuenta con HDS
                          </Typography>
                        </CardContent>
                      </Card>
                    )}

                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">indicar  SUSPEL</FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="golpeadorpor"
                        name="golpeadorpor"
                        value={formData?.p8indicateSuspel}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p8indicateSuspel === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p8indicateSuspel === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p8indicateSuspel === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">Guantes Showa</FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="golpeadocon"
                        name="golpeadocon"
                        value={formData?.p8showaGlove}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p8showaGlove === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p8showaGlove === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p8showaGlove === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">
                        Careta Facial
                      </FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="caida"
                        name="caida"
                        value={formData?.p8faceShield}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p8faceShield === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p8faceShield === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p8faceShield === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">
                        Traje Antiácido
                      </FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="contactoconobjetoscortantes"
                        name="contactoconobjetoscortantes"
                        value={formData?.p8antSuit}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p8antSuit === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p8antSuit === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p8antSuit === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">
                        Bota de goma
                      </FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="contactoconobjetoscalientes"
                        name="contactoconobjetoscalientes"
                        value={formData?.p8rubberBoot}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p8rubberBoot === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p8rubberBoot === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p8rubberBoot === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">
                        Kit Antiderrame
                      </FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="proyecciondeparticulas"
                        name="proyecciondeparticulas"
                        value={formData?.p8antSpillKit}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p8antSpillKit === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p8antSpillKit === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p8antSpillKit === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <FormControl sx={{ display: 'flex', mb: 10 }} component="fieldset">
                      <FormLabel component="legend">
                        Extensiones eléctricas chequeadas
                      </FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="proyecciondeparticulas"
                        name="proyecciondeparticulas"
                        value={formData?.p8extensionElectricalCheck}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p8extensionElectricalCheck === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p8extensionElectricalCheck === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p8extensionElectricalCheck === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>

                  </Box>

                  {(formData?.p8extensionElectricalCheckImage?.length > 1
                    && formData?.p8extensionElectricalCheckImage !== 'none')
                    && (
                      <Card sx={{ maxWidth: 345, mt: 2, mb: 4 }}>
                        <CardMedia
                          component="img"
                          height="140"
                          image={
                            formData?.p8extensionElectricalCheckImage
                            && formData?.p8extensionElectricalCheckImage
                          }
                          alt="extensiones eléctricas chequeadas"
                        />
                        <CardContent>
                          <Typography gutterBottom variant="subtitle1" component="div">
                            Extensiones eléctricas chequeadas
                          </Typography>
                        </CardContent>
                      </Card>
                    )}

                </Box>

                <Typography
                  sx={{ mb: 2, mt: 2 }}
                  align="center"
                  variant="h6"
                  color="text.secondary"
                  gutterBottom
                >
                  IZAJE
                </Typography>

                <Grid display="flex" justifyContent="center" item xs={12}>
                  <FormControlLabel
                    control={(
                      <YellowSwitch
                        checked={formData?.p9Show}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    )}
                    label={formData?.p9Show ? 'Esta página aplica' : 'Esta página no aplica'}
                  />
                </Grid>

                <Box
                  component="div"
                  sx={{
                    display: formData?.p9Show ? 'block' : 'none',
                  }}
                >
                  <Box sx={{
                    display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                  }}
                  >
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">Revisión Eslingas</FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="atrapamiento"
                        name="atrapamiento"
                        value={formData?.p9eslingasCheck}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p9eslingasCheck === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p9eslingasCheck === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p9eslingasCheck === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>

                  </Box>

                  {(formData?.p9eslingasCheckImage?.length > 1 && formData?.p9eslingasCheckImage !== 'none')
                    && (
                      <Card sx={{ maxWidth: 345, mt: 2, mb: 4 }}>
                        <CardMedia
                          component="img"
                          height="140"
                          image={
                            formData?.p9eslingasCheckImage
                            && formData?.p9eslingasCheckImage
                          }
                          alt="revisión Eslingas"
                        />
                        <CardContent>
                          <Typography gutterBottom variant="subtitle1" component="div">
                            Revisión Eslingas
                          </Typography>
                        </CardContent>
                      </Card>
                    )}

                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">Revisión de puntos de anclaje</FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="aplastamiento"
                        name="aplastamiento"
                        value={formData?.p9ancPointCheck}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p9ancPointCheck === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p9ancPointCheck === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p9ancPointCheck === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  <Grid display="flex" justifyContent="center" sx={{ mb: 2 }} item xs={12}>
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">Establecer perímetro de seguridad (indicar área)</FormLabel>
                      <Grid display="flex" justifyContent="center" item xs={12}>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row', justifyContent: 'center' }}
                          aria-label="golpeadorpor"
                          name="golpeadorpor"
                          value={formData?.p9securityPerimeter}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p9securityPerimeter === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p9securityPerimeter === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p9securityPerimeter === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">Participación RIGGER</FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="golpeadocon"
                        name="golpeadocon"
                        value={formData?.p9riggerParticipation}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p9riggerParticipation === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p9riggerParticipation === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p9riggerParticipation === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">
                        Requiere bloquear áreas de tránsito
                      </FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="caida"
                        name="caida"
                        value={formData?.p9transitAreas}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p9transitAreas === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p9transitAreas === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p9transitAreas === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">
                        Informa a jefaturas de áreas a bloquear
                      </FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="contactoconobjetoscortantes"
                        name="contactoconobjetoscortantes"
                        value={formData?.p9informBlockAreasToJef}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p9informBlockAreasToJef === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p9informBlockAreasToJef === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p9informBlockAreasToJef === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">
                        Delimitación del área
                      </FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="contactoconobjetoscalientes"
                        name="contactoconobjetoscalientes"
                        value={formData?.p9areaDelimitation}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p9areaDelimitation === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p9areaDelimitation === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p9areaDelimitation === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <FormControl sx={{ display: 'flex', mb: 10 }} component="fieldset">
                      <FormLabel component="legend">
                        Chequeo previo del camión
                      </FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="proyecciondeparticulas"
                        name="proyecciondeparticulas"
                        value={formData?.p9truckPrevCheck}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p9truckPrevCheck === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p9truckPrevCheck === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p9truckPrevCheck === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  {(formData?.p9truckPrevCheckImage?.length > 1 && formData?.p9truckPrevCheckImage !== 'none')
                    && (
                      <Card sx={{ maxWidth: 345, mt: 2, mb: 4 }}>
                        <CardMedia
                          component="img"
                          height="140"
                          image={
                            formData?.p9truckPrevCheckImage
                            && formData?.p9truckPrevCheckImage
                          }
                          alt="chequeo previo del camión"
                        />
                        <CardContent>
                          <Typography gutterBottom variant="subtitle1" component="div">
                            Chequeo previo del camión
                          </Typography>
                        </CardContent>
                      </Card>
                    )}

                </Box>

                <Typography
                  sx={{ mb: 2, mt: 2 }}
                  align="center"
                  variant="h6"
                  color="text.secondary"
                  gutterBottom
                >
                  USO DE QUÍMICOS
                </Typography>

                <Grid display="flex" justifyContent="center" item xs={12}>
                  <FormControlLabel
                    control={(
                      <YellowSwitch
                        checked={formData?.p10show}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    )}
                    label={formData?.p10show ? 'Esta página aplica' : 'Esta página no aplica'}
                  />
                </Grid>

                <Box
                  component="div"
                  sx={{
                    display: formData?.p10show ? 'block' : 'none',
                  }}
                >
                  <TextField
                    sx={{ mb: 2 }}
                    fullWidth
                    label="1 - Nombre del quimico a utilizar"
                    variant="outlined"
                    value={formData?.p10chemName1}
                  />
                  <Grid display="flex">
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      label="Salud"
                      variant="outlined"
                      value={formData?.p10chemName1health}
                    />
                    <TextField
                      sx={{ mb: 2, ml: 2 }}
                      fullWidth
                      label="Inflamable"
                      variant="outlined"
                      value={formData?.p10chemName1inflam}
                    />
                  </Grid>
                  <Grid display="flex">
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      label="Reactividad"
                      variant="outlined"
                      value={formData?.p10chemName1reactivity}
                    />
                    <TextField
                      sx={{ mb: 2, ml: 2 }}
                      fullWidth
                      label="Específico"
                      variant="outlined"
                      value={formData?.p10chemName1specific}
                    />
                  </Grid>

                  <TextField
                    sx={{ mt: 4, mb: 2 }}
                    fullWidth
                    label="2 - Nombre del quimico a utilizar"
                    variant="outlined"
                    value={formData?.p10chemName2}
                  />
                  <Grid display="flex">
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      label="Salud"
                      variant="outlined"
                      value={formData?.p10chemName2health}
                    />
                    <TextField
                      sx={{ mb: 2, ml: 2 }}
                      fullWidth
                      label="Inflamable"
                      variant="outlined"
                      value={formData?.p10chemName2inflam}
                    />
                  </Grid>
                  <Grid display="flex">
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      label="Reactividad"
                      variant="outlined"
                      value={formData?.p10chemName2reactivity}
                    />
                    <TextField
                      sx={{ mb: 2, ml: 2 }}
                      fullWidth
                      label="Específico"
                      variant="outlined"
                      value={formData?.p10chemName2specific}
                    />
                  </Grid>

                  <TextField
                    sx={{ mt: 4, mb: 2 }}
                    fullWidth
                    label="3 - Nombre del quimico a utilizar"
                    variant="outlined"
                    value={formData?.p10chemName3}
                  />
                  <Grid display="flex">
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      label="Salud"
                      variant="outlined"
                      value={formData?.p10chemName3health}
                    />
                    <TextField
                      sx={{ mb: 2, ml: 2 }}
                      fullWidth
                      label="Inflamable"
                      variant="outlined"
                      value={formData?.p10chemName3inflam}
                    />
                  </Grid>
                  <Grid display="flex">
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      label="Reactividad"
                      variant="outlined"
                      value={formData?.p10chemName3reactivity}
                    />
                    <TextField
                      sx={{ mb: 2, ml: 2 }}
                      fullWidth
                      label="Específico"
                      variant="outlined"
                      value={formData?.p10chemName3specific}
                    />
                  </Grid>

                  <TextField
                    sx={{ mt: 4, mb: 2 }}
                    fullWidth
                    label="4 - Nombre del quimico a utilizar"
                    variant="outlined"
                    value={formData?.p10chemName4}
                  />
                  <Grid display="flex">
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      label="Salud"
                      variant="outlined"
                      value={formData?.p10chemName4health}
                    />
                    <TextField
                      sx={{ mb: 2, ml: 2 }}
                      fullWidth
                      label="Inflamable"
                      variant="outlined"
                      value={formData?.p10chemName4inflam}
                    />
                  </Grid>
                  <Grid display="flex">
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      label="Reactividad"
                      variant="outlined"
                      value={formData?.p10chemName4reactivity}
                    />
                    <TextField
                      sx={{ mb: 2, ml: 2 }}
                      fullWidth
                      label="Específico"
                      variant="outlined"
                      value={formData?.p10chemName4specific}
                    />
                  </Grid>

                  <TextField
                    sx={{ mt: 4, mb: 2 }}
                    fullWidth
                    label="5 - Nombre del quimico a utilizar"
                    variant="outlined"
                    value={formData?.p10chemName5}
                  />
                  <Grid display="flex">
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      label="Salud"
                      variant="outlined"
                      value={formData?.p10chemName5health}
                    />
                    <TextField
                      sx={{ mb: 2, ml: 2 }}
                      fullWidth
                      label="Inflamable"
                      variant="outlined"
                      value={formData?.p10chemName5inflam}
                    />
                  </Grid>
                  <Grid display="flex" sx={{ mb: 10 }}>
                    <TextField
                      sx={{ mb: 2 }}
                      fullWidth
                      label="Reactividad"
                      variant="outlined"
                      value={formData?.p10chemName5reactivity}
                    />
                    <TextField
                      sx={{ mb: 2, ml: 2 }}
                      fullWidth
                      label="Específico"
                      variant="outlined"
                      value={formData?.p10chemName5specific}
                    />
                  </Grid>

                </Box>

                <Typography
                  sx={{ mb: 2, mt: 2 }}
                  align="center"
                  variant="h6"
                  color="text.secondary"
                  gutterBottom
                >
                  MANTENIMIENTO Y SS.GG.
                </Typography>

                <Grid display="flex" justifyContent="center" item xs={12}>
                  <FormControlLabel
                    control={(
                      <YellowSwitch
                        checked={formData?.p11show}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    )}
                    label={formData?.p11show ? 'Esta página aplica' : 'Esta página no aplica'}
                  />
                </Grid>

                <Box
                  component="div"
                  sx={{
                    display: formData?.p11show ? 'block' : 'none',
                  }}
                >
                  <Box sx={{
                    display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                  }}
                  >
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">Mascarilla</FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="atrapamiento"
                        name="atrapamiento"
                        value={formData?.p11mask}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p11mask === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p11mask === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p11mask === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">Guantes de Cabritilla</FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="aplastamiento"
                        name="aplastamiento"
                        value={formData?.p11cabritillaGlove}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p11cabritillaGlove === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p11cabritillaGlove === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p11cabritillaGlove === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">Respiradores</FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="golpeadorpor"
                        name="golpeadorpor"
                        value={formData?.p11breather}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p11breather === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p11breather === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p11breather === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">Carga manual Max. 25 Kg.</FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="golpeadocon"
                        name="golpeadocon"
                        value={formData?.p11manualLoadMax25}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p11manualLoadMax25 === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p11manualLoadMax25 === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p11manualLoadMax25 === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">
                        Utilización de señalética especial
                      </FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="caida"
                        name="caida"
                        value={formData?.p11useoFSignal}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p11useoFSignal === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p11useoFSignal === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p11useoFSignal === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">
                        Delimitación del área
                      </FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="contactoconobjetoscortantes"
                        name="contactoconobjetoscortantes"
                        value={formData?.p11areaDelimitation}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p11areaDelimitation === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p11areaDelimitation === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p11areaDelimitation === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">
                        Extensiones eléctricas chequeadas
                      </FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="contactoconobjetoscalientes"
                        name="contactoconobjetoscalientes"
                        value={formData?.p11electricalExtensionCheck}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p11electricalExtensionCheck === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p11electricalExtensionCheck === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p11electricalExtensionCheck === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  {(formData?.p11electricalExtensionCheckImage?.length > 1
                    && formData?.p11electricalExtensionCheckImage !== 'none')
                    && (
                      <Card sx={{ maxWidth: 345, mt: 2, mb: 4 }}>
                        <CardMedia
                          component="img"
                          height="140"
                          image={
                            formData?.p11electricalExtensionCheckImage
                            && formData?.p11electricalExtensionCheckImage
                          }
                          alt="extensiones eléctricas chequeadas"
                        />
                        <CardContent>
                          <Typography gutterBottom variant="subtitle1" component="div">
                            Extensiones eléctricas chequeadas
                          </Typography>
                        </CardContent>
                      </Card>
                    )}

                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">
                        Revisión de herramientas
                      </FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="proyecciondeparticulas"
                        name="proyecciondeparticulas"
                        value={formData?.p11toolCheck}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p11toolCheck === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p11toolCheck === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p11toolCheck === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">
                        Iluminación adecuada para el trabajo
                      </FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="proyecciondeparticulas"
                        name="proyecciondeparticulas"
                        value={formData?.p11lightningToWork}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p11lightningToWork === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p11lightningToWork === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p11lightningToWork === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">
                        Área despejada
                      </FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="proyecciondeparticulas"
                        name="proyecciondeparticulas"
                        value={formData?.p11clearArea}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p11clearArea === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p11clearArea === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p11clearArea === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <FormControl sx={{ display: 'flex', mb: 10 }} component="fieldset">
                      <FormLabel component="legend">
                        Utilización de grúas
                      </FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="proyecciondeparticulas"
                        name="proyecciondeparticulas"
                        value={formData?.p11useOfGrua}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p11useOfGrua === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p11useOfGrua === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p11useOfGrua === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Box>

                <Typography
                  sx={{ mb: 2, mt: 2 }}
                  align="center"
                  variant="h6"
                  color="text.secondary"
                  gutterBottom
                >
                  BUENAS PRÁCTICAS DE MANUFACTURA
                </Typography>

                <Grid display="flex" justifyContent="center" item xs={12}>
                  <FormControlLabel
                    control={(
                      <YellowSwitch
                        checked={formData?.p12show}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    )}
                    label={formData?.p12show ? 'Esta página aplica' : 'Esta página no aplica'}
                  />
                </Grid>

                <Box
                  component="div"
                  sx={{
                    display: formData?.p12show ? 'block' : 'none',
                  }}
                >
                  <Grid display="flex" justifyContent="center" item xs={12}>
                    <TextField
                      sx={{ mb: 2, width: 300 }}
                      fullWidth
                      label="Evaluado por : "
                      variant="outlined"
                      value={formData?.p12evaluatorName}
                    />
                  </Grid>
                  <Grid display="flex" justifyContent="center" item xs={12}>
                    {formData?.p12evaluationTime ? (
                      <TextField
                        sx={{ mb: 2, width: 300 }}
                        fullWidth
                        label="Hora inicial"
                        variant="outlined"
                        value={`${formData?.p12evaluationTime && formData?.p12evaluationTime.toDate().getHours()}:${formData?.p2startTime && formData?.p2startTime.toDate().getMinutes()}`}
                        InputLabelProps={{ shrink: true }}
                      />
                    ) : null}

                  </Grid>

                  <Box sx={{
                    display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                  }}
                  >
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">Aislamiento total del área</FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="atrapamiento"
                        name="atrapamiento"
                        value={formData?.p12areaClear}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p12areaClear === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p12areaClear === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p12areaClear === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">Materiales dentro de la zona aislada</FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="aplastamiento"
                        name="aplastamiento"
                        value={formData?.p12materialsWithinTheZone}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p12materialsWithinTheZone === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p12materialsWithinTheZone === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p12materialsWithinTheZone === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">Generación de polvos o residuos</FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="golpeadorpor"
                        name="golpeadorpor"
                        value={formData?.p12dustGeneration}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p12dustGeneration === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p12dustGeneration === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p12dustGeneration === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">Ingreso de bebidas o alimentos</FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="golpeadocon"
                        name="golpeadocon"
                        value={formData?.p12foodIn}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p12foodIn === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p12foodIn === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p12foodIn === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 10 }}>
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">
                        Cumple BMP
                      </FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="caida"
                        name="caida"
                        value={formData?.p12hasBmp}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p12hasBmp === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p12hasBmp === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p12hasBmp === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Box>

                <Typography
                  sx={{ mb: 2, mt: 2 }}
                  align="center"
                  variant="h6"
                  color="text.secondary"
                  gutterBottom
                >
                  TRABAJO EN CALIENTE
                </Typography>

                <Grid display="flex" justifyContent="center" item xs={12}>
                  <FormControlLabel
                    control={(
                      <YellowSwitch
                        checked={formData?.p13show}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    )}
                    label={formData?.p13show ? 'Esta página aplica' : 'Esta página no aplica'}
                  />
                </Grid>

                <Box
                  component="div"
                  sx={{
                    display: formData?.p13show ? 'block' : 'none',
                  }}
                >
                  <Grid display="flex" justifyContent="center" item xs={12}>
                    <TextField
                      sx={{ mb: 2, width: 300 }}
                      fullWidth
                      label="Permiso de Trabajo en Caliente Nº : "
                      variant="outlined"
                      value={formData?.p13hotWorkFormNumber}
                    />

                  </Grid>

                  {(formData?.p13hotWorkFormNumberImage?.length > 1
                    && formData?.p13hotWorkFormNumberImage !== 'none')
                    && (
                      <Card sx={{ maxWidth: 345, mt: 2, mb: 4 }}>
                        <CardMedia
                          component="img"
                          height="140"
                          image={
                            formData?.p13hotWorkFormNumberImage
                            && formData?.p13hotWorkFormNumberImage
                          }
                          alt="permiso de trabajo en caliente n"
                        />
                        <CardContent>
                          <Typography gutterBottom variant="subtitle1" component="div">
                            Permiso de Trabajo en Caliente
                          </Typography>
                        </CardContent>
                      </Card>
                    )}

                  <Box sx={{
                    display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                  }}
                  >
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">Delimitación del área</FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="atrapamiento"
                        name="atrapamiento"
                        value={formData?.p13areaDelimitation}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p13areaDelimitation === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p13areaDelimitation === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p13areaDelimitation === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">Extensiones eléctricas chequeadas</FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="aplastamiento"
                        name="aplastamiento"
                        value={formData?.p13electricalExtensionCheck}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p13electricalExtensionCheck === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p13electricalExtensionCheck === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p13electricalExtensionCheck === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>

                  </Box>

                  {(formData?.p13electricalExtensionCheckImage?.length > 1
                    && formData?.p13electricalExtensionCheckImage !== 'none')
                    && (
                      <Card sx={{ maxWidth: 345, mt: 2, mb: 4 }}>
                        <CardMedia
                          component="img"
                          height="140"
                          image={
                            formData?.p13electricalExtensionCheckImage
                            && formData?.p13electricalExtensionCheckImage
                          }
                          alt="extensiones eléctricas chequeadas"
                        />
                        <CardContent>
                          <Typography gutterBottom variant="subtitle1" component="div">
                            Extensiones eléctricas chequeadas
                          </Typography>
                        </CardContent>
                      </Card>
                    )}

                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 10 }}>
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">Aviso a Brigada</FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row' }}
                        aria-label="golpeadorpor"
                        name="golpeadorpor"
                        value={formData?.p13notificationToBrig}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p13notificationToBrig === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p13notificationToBrig === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p13notificationToBrig === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Box>

                <Typography
                  sx={{ mb: 2, mt: 2 }}
                  align="center"
                  variant="h6"
                  color="text.secondary"
                  gutterBottom
                >
                  {`EQUIPOS DE PROTECCIÓN PERSONAL ${!formData?.p14securityGlass ? '(No disponible)' : ''}`}
                </Typography>
                {formData?.p14securityGlass ? (
                  <>
                    <Box sx={{
                      display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                    }}
                    >
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Lentes de seguridad</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="atrapamiento"
                          name="atrapamiento"
                          value={formData?.p14securityGlass}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p14securityGlass === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p14securityGlass === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p14securityGlass === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Zapatos de Seguridad</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="aplastamiento"
                          name="aplastamiento"
                          value={formData?.p14securityShoes}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p14securityShoes === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p14securityShoes === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p14securityShoes === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Protector Auditivo (Fonos)</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="golpeadorpor"
                          name="golpeadorpor"
                          value={formData?.p14earProtector}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p14earProtector === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p14earProtector === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p14earProtector === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Casco de Seguridad</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="golpeadorpor"
                          name="golpeadorpor"
                          value={formData?.p14securityHeadProtection}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p14securityHeadProtection === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p14securityHeadProtection === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p14securityHeadProtection === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Chaleco Reflectante</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="golpeadorpor"
                          name="golpeadorpor"
                          value={formData?.p14reflectJacket}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p14reflectJacket === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p14reflectJacket === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p14reflectJacket === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Proteccion filtro UV</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="golpeadorpor"
                          name="golpeadorpor"
                          value={formData?.p14uvFilterProtection}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p14uvFilterProtection === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p14uvFilterProtection === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p14uvFilterProtection === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Traje Antiácido</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="golpeadorpor"
                          name="golpeadorpor"
                          value={formData?.p14antSuit}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p14antSuit === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p14antSuit === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p14antSuit === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Botas Seguridad resistente al agua</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="golpeadorpor"
                          name="golpeadorpor"
                          value={formData?.p14bootsResistant}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p14bootsResistant === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p14bootsResistant === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p14bootsResistant === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Careta Facial</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="golpeadorpor"
                          name="golpeadorpor"
                          value={formData?.p14faceShield}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p14faceShield === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p14faceShield === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p14faceShield === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Máscara de Soldar</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="golpeadorpor"
                          name="golpeadorpor"
                          value={formData?.p14soldarMask}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p14soldarMask === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p14soldarMask === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p14soldarMask === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Traje de Soldador</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="golpeadorpor"
                          name="golpeadorpor"
                          value={formData?.p14soldarSuit}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p14soldarSuit === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p14soldarSuit === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p14soldarSuit === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Coleto de Cuero</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="golpeadorpor"
                          name="golpeadorpor"
                          value={formData?.p14leatherColeto}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p14leatherColeto === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p14leatherColeto === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p14leatherColeto === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Polainas de Cuero</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="golpeadorpor"
                          name="golpeadorpor"
                          value={formData?.p14leatherPolaina}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p14leatherPolaina === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p14leatherPolaina === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p14leatherPolaina === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Árnes de Seguridad con cuatro argollas</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="golpeadorpor"
                          name="golpeadorpor"
                          value={formData?.p14fourHookHarness}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p14fourHookHarness === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p14fourHookHarness === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p14fourHookHarness === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>

                    </Box>

                    {(formData?.p14fourHookHarnessImage?.length > 1
                      && formData?.p14fourHookHarnessImage !== 'none')
                      && (
                        <Card sx={{ maxWidth: 345, mt: 2, mb: 4 }}>
                          <CardMedia
                            component="img"
                            height="140"
                            image={
                              formData?.p14fourHookHarnessImage
                              && formData?.p14fourHookHarnessImage
                            }
                            alt="arnes de seguridad con cuatro argollas"
                          />
                          <CardContent>
                            <Typography gutterBottom variant="subtitle1" component="div">
                              Árnes de Seguridad con cuatro argollas
                            </Typography>
                          </CardContent>
                        </Card>
                      )}

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Cola de vida (doble)</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="golpeadorpor"
                          name="golpeadorpor"
                          value={formData?.p14doubleLitail}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p14doubleLitail === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p14doubleLitail === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p14doubleLitail === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    {(formData?.p14doubleLitailImage?.length > 1
                      && formData?.p14doubleLitailImage !== 'none')
                      && (
                        <Card sx={{ maxWidth: 345, mt: 2, mb: 4 }}>
                          <CardMedia
                            component="img"
                            height="140"
                            image={
                              formData?.p14doubleLitailImage
                              && formData?.p14doubleLitailImage
                            }
                            alt="cola de vida (doble)"
                          />
                          <CardContent>
                            <Typography gutterBottom variant="subtitle1" component="div">
                              Cola de vida (doble)
                            </Typography>
                          </CardContent>
                        </Card>
                      )}

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Absorbedor de impacto</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="golpeadorpor"
                          name="golpeadorpor"
                          value={formData?.p14impactAbsorb}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p14impactAbsorb === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p14impactAbsorb === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p14impactAbsorb === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>

                    </Box>

                    {(formData?.p14impactAbsorbImage?.length > 1 && formData?.p14impactAbsorbImage !== 'none')
                      && (
                        <Card sx={{ maxWidth: 345, mt: 2, mb: 4 }}>
                          <CardMedia
                            component="img"
                            height="140"
                            image={
                              formData?.p14impactAbsorbImage
                              && formData?.p14impactAbsorbImage
                            }
                            alt="absorbedor de impacto"
                          />
                          <CardContent>
                            <Typography gutterBottom variant="subtitle1" component="div">
                              Absorbedor de impacto
                            </Typography>
                          </CardContent>
                        </Card>
                      )}

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Mosquetón de escalada</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="golpeadorpor"
                          name="golpeadorpor"
                          value={formData?.p14escaladaMosqueton}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p14escaladaMosqueton === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p14escaladaMosqueton === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p14escaladaMosqueton === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>

                    </Box>

                    {(formData?.p14escaladaMosquetonImage?.length > 1 && formData?.p14escaladaMosquetonImage !== 'none')
                      && (
                        <Card sx={{ maxWidth: 345, mt: 2, mb: 4 }}>
                          <CardMedia
                            component="img"
                            height="140"
                            image={
                              formData?.p14escaladaMosquetonImage
                              && formData?.p14escaladaMosquetonImage
                            }
                            alt="mosquetón de escalada"
                          />
                          <CardContent>
                            <Typography gutterBottom variant="subtitle1" component="div">
                              Mosquetón de escalada
                            </Typography>
                          </CardContent>
                        </Card>
                      )}

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                      <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                        <FormLabel component="legend">Mascarilla con Filtro</FormLabel>
                        <RadioGroup
                          sx={{ flex: '1', flexDirection: 'row' }}
                          aria-label="golpeadorpor"
                          name="golpeadorpor"
                          value={formData?.p14filterMask}
                        >
                          <FormControlLabel value="SI" control={<Radio checked={formData?.p14filterMask === 'SI'} />} label="SI" />
                          <FormControlLabel value="NO" control={<Radio checked={formData?.p14filterMask === 'NO'} />} label="NO" />
                          <FormControlLabel value="NA" control={<Radio checked={formData?.p14filterMask === 'NA'} />} label="N/A" />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </>
                ) : null}

                <Typography
                  sx={{ mb: 2, mt: 2 }}
                  align="center"
                  variant="h6"
                  color="text.secondary"
                  gutterBottom
                >
                  BLOQUEO DE ENERGÍAS
                </Typography>

                <Grid display="flex" justifyContent="center" item xs={12}>
                  <FormControlLabel
                    control={(
                      <YellowSwitch
                        checked={formData?.p15show && formData?.p15show}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    )}
                    label={formData?.p15show && formData?.p15show ? 'Esta página aplica' : 'Esta página no aplica'}
                  />
                </Grid>

                <Box
                  component="div"
                  sx={{
                    display: formData?.p15show ? 'block' : 'none',
                  }}
                >
                  <Grid display="flex" justifyContent="center" item xs={12}>
                    <TextField
                      sx={{ mt: 2, mb: 2, width: 300 }}
                      fullWidth
                      label="Nombre del personal a cargo del bloqueo : "
                      variant="outlined"
                      value={formData?.p15blockingPersonName && formData?.p15blockingPersonName}
                    />
                  </Grid>
                  <Grid display="flex" justifyContent="center" item xs={12}>
                    {formData?.p15dateTimeLotoStart ? (
                      <TextField
                        sx={{ mt: 2, mb: 2, width: 300 }}
                        fullWidth
                        label="Fecha / Hora inicio del LOTO : "
                        variant="outlined"
                        value={`${formData?.p15dateTimeLotoStart && formData?.p15dateTimeLotoStart.toDate()
                          .getDate()}-${monthNames[formData?.p15dateTimeLotoStart?.toDate().getMonth()]}-${formData?.p15dateTimeLotoStart && formData?.p15dateTimeLotoStart.toDate().getFullYear()} / ${formData?.p15dateTimeLotoStart && formData?.p15dateTimeLotoStart.toDate().getHours()}:${formData?.p15dateTimeLotoStart && formData?.p15dateTimeLotoStart.toDate().getMinutes() < 10 ? '0' : ''}${formData?.p15dateTimeLotoStart && formData?.p15dateTimeLotoStart.toDate().getMinutes()}`}
                        InputLabelProps={{ shrink: true }}
                      />
                    ) : null}

                  </Grid>
                  <Grid display="flex" justifyContent="center" item xs={12}>
                    {formData?.p15dateTimeLotoFinish ? (
                      <TextField
                        sx={{ mt: 2, mb: 2, width: 300 }}
                        fullWidth
                        label="Fecha / Hora finalización del LOTO : "
                        variant="outlined"
                        value={`${formData?.p15dateTimeLotoFinish && formData?.p15dateTimeLotoFinish.toDate()
                          .getDate()}-${monthNames[formData?.p15dateTimeLotoFinish && formData?.p15dateTimeLotoFinish?.toDate().getMonth()]}-${formData?.p15dateTimeLotoFinish && formData?.p15dateTimeLotoFinish.toDate().getFullYear()} / ${formData?.p15dateTimeLotoFinish && formData?.p15dateTimeLotoFinish.toDate().getHours()}:${formData?.p15dateTimeLotoFinish && formData?.p15dateTimeLotoFinish.toDate().getMinutes() < 10 ? '0' : ''}${formData?.p15dateTimeLotoFinish && formData?.p15dateTimeLotoFinish.toDate().getMinutes()}`}
                        InputLabelProps={{ shrink: true }}
                      />
                    ) : null}

                  </Grid>

                  <Typography
                    sx={{ mt: 4 }}
                    textAlign="center"
                  >
                    Tipo de Energía a Bloquear:
                  </Typography>
                  <Grid sx={{ mt: 1 }} display="flex" justifyContent="center" alignItems="center">

                    <Typography>Eléctrica</Typography>
                    <Checkbox
                      checked={formData?.p15blockingEnTypeElectrical === true}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Typography>Hidraulica</Typography>
                    <Checkbox
                      checked={formData?.p15blockingEnTypeHidraulical === true}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Typography>Mecánica</Typography>
                    <Checkbox
                      checked={formData?.p15blockingEnMechanical === true}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Typography>Potencial</Typography>
                    <Checkbox
                      checked={formData?.p15blockingEnPotencial === true}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </Grid>
                  {/* </div> */}

                  <Box sx={{
                    display: 'flex', justifyContent: 'center', mt: 4, mb: 2,
                  }}
                  >
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">Capacitacion control de energias peligrosas</FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row', justifyContent: 'center' }}
                        aria-label="atrapamiento"
                        name="atrapamiento"
                        value={formData?.p15dangEnergiesControl}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p15dangEnergiesControl === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p15dangEnergiesControl === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p15dangEnergiesControl === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">Conoce las fuentes de energias de la maquina</FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row', justifyContent: 'center' }}
                        aria-label="aplastamiento"
                        name="aplastamiento"
                        value={formData?.p15knowEnergySourceMachine}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p15knowEnergySourceMachine === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p15knowEnergySourceMachine === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p15knowEnergySourceMachine === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">Conoc del LOTO</FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row', justifyContent: 'center' }}
                        aria-label="golpeadorpor"
                        name="golpeadorpor"
                        value={formData?.p15concLoto}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p15concLoto === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p15concLoto === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p15concLoto === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">Posee candado de bloqueo, tarjeta de identificacion y dispositivo de bloqueo</FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row', justifyContent: 'center' }}
                        aria-label="golpeadorpor"
                        name="golpeadorpor"
                        value={formData?.p15hasLockIdCardBlockDevice}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p15hasLockIdCardBlockDevice === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p15hasLockIdCardBlockDevice === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p15hasLockIdCardBlockDevice === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">Guantes dielectricos</FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row', justifyContent: 'center' }}
                        aria-label="golpeadorpor"
                        name="golpeadorpor"
                        value={formData?.p15dielectricGlove}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p15dielectricGlove === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p15dielectricGlove === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p15dielectricGlove === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">Zapatos de seguridad dielectrico</FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row', justifyContent: 'center' }}
                        aria-label="golpeadorpor"
                        name="golpeadorpor"
                        value={formData?.p15dielectricSecurityShoe}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p15dielectricSecurityShoe === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p15dielectricSecurityShoe === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p15dielectricSecurityShoe === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 10 }}>
                    <FormControl sx={{ display: 'flex', mb: 2 }} component="fieldset">
                      <FormLabel component="legend">Lentes de seguridad dielectrico</FormLabel>
                      <RadioGroup
                        sx={{ flex: '1', flexDirection: 'row', justifyContent: 'center' }}
                        aria-label="golpeadorpor"
                        name="golpeadorpor"
                        value={formData?.p15dielectricSecurityGlass}
                      >
                        <FormControlLabel value="SI" control={<Radio checked={formData?.p15dielectricSecurityGlass === 'SI'} />} label="SI" />
                        <FormControlLabel value="NO" control={<Radio checked={formData?.p15dielectricSecurityGlass === 'NO'} />} label="NO" />
                        <FormControlLabel value="NA" control={<Radio checked={formData?.p15dielectricSecurityGlass === 'NA'} />} label="N/A" />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Box>

              </Grid>
            </Grid>
          </Slide>
        </>
      ) : <FullPageLoader />}
    </DashboardLayout>
  )
}

export default ReviseStep1