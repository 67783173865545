import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import DashboardLayout from '../layouts/DashboardLayout'

const Support = () => (
  <>
    <DashboardLayout>
      <Container
        maxWidth="lg"
        sx={{ mt: 4, mb: 4 }}
      >
        <Typography variant="body1" gutterBottom>
          Por cualquier duda o consulta que requiera hacer
        </Typography>
        <Typography variant="body1" gutterBottom>
          escriba a soporte@hseqtechnology.cl
        </Typography>
        <Typography variant="body1" gutterBottom>
          y responderemos su inquietud lo antes posible
        </Typography>
      </Container>
    </DashboardLayout>
  </>
)

export default Support